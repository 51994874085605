import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import {
  urlGraficoMarmitasPorMeses,
  tokenUsuario,
} from "../../utils/constants";
import Chart from "chart.js/auto";
import { CircularProgress } from "@mui/material";

export default function GraficoMarmitasMeses({ ano }) {
  const chartRef = useRef(null);
  const [chart, setChart] = useState(null);
  const [loading, setLoading] = useState(false);

  const updateChart = (labels, data, backgroundColors) => {
    if (chart) {
      chart.destroy();
    }

    const ctx = chartRef.current.getContext("2d");
    const newChart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: labels,
        datasets: [
          {
            label: "Número de Marmitas",
            data: data,
            backgroundColor: backgroundColors,
            barThickness: 30,
            borderRadius: 20,
          },
        ],
      },
      options: {
        scales: {
          x: {
            beginAtZero: true,
            grid: {
              display: false,
            },
            ticks: {
              display: true,
            },
          },
          y: {
            beginAtZero: true,
            grid: {
              display: false,
            },
            ticks: {
              display: true,
            },
          },
        },
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });

    setChart(newChart);
  };

  const listarItens = async () => {
    try {
      const response = await axios.post(urlGraficoMarmitasPorMeses, {
        data: ano,
        token: tokenUsuario,
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  
  const carregarItens = async () => {
    const itens = await listarItens();
    const cores = [
      "#FF9C2D",
      "#00C3B3",
      "#838EFC",
      "#FF6600",
      "#00A781",
      "#1A50FE",
      "#F8D45F",
      "#F1639F",
      "#2A72B4",
      "#30C6F8",
      "#3D2989",
    ];
    const labels = itens.map((item) => item.Periodo);
    const data = itens.map((item) => item.total);
    const backgroundColors = cores;
    updateChart(labels, data, backgroundColors);
  };

  useEffect(() => {
    carregarItens();
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [ano]);

  return (
    <>
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress
            style={{ height: "20px", width: "20px" }}
            color="primary"
          />
        </div>
      ) : (
        <canvas ref={chartRef} style={{ maxHeight: "400px" }}></canvas>
      )}
    </>
  );
}
