import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";
import CloseIcon from "@mui/icons-material/Close";

export const ModalCadastros = ({ modalCadastro, closeModalRelatorio }) => {
  const handleNavigate = (path) => {
    closeModalRelatorio();
    navigate(path);
  };

  const listarCadastros = [
    { text: "Produtos", icon: <LabelOutlinedIcon />, path: "/produtos" },
    {
      text: "Categorias dos ingredientes",
      icon: <LabelOutlinedIcon />,
      path: "/categorias",
    },
    {
      text: "Ingredientes",
      icon: <LabelOutlinedIcon />,
      path: "/ingredientes",
    },
    {
      text: "Formas de pagamento",
      icon: <LabelOutlinedIcon />,
      path: "/forma_pagamentos",
    },
    { text: "Clientes", icon: <LabelOutlinedIcon />, path: "/clientes" },
    { text: "Clientes ativos", icon: <LabelOutlinedIcon />, path: "/clientes/ativos" },
    { text: "Empresas", icon: <LabelOutlinedIcon />, path: "/empresas" },
  ];

  const navigate = useNavigate();

  return (
    <>
      <Drawer
        anchor="left"
        open={modalCadastro}
        onClose={closeModalRelatorio}
        className="my-custom-class"
      >
        <List>
          {listarCadastros.map((item, index) => (
            <ListItem
              button
              key={index}
              onClick={() => handleNavigate(item.path)}
            >
              <LabelOutlinedIcon style={{ fontSize: "16px" }} /> &nbsp; &nbsp;
              <ListItemText
                primaryTypographyProps={{ fontSize: "14px" }}
                primary={item.text}
              />
            </ListItem>
          ))}
          <ListItem button key="Fechar" onClick={closeModalRelatorio}>
            <CloseIcon style={{ fontSize: "16px" }} />
            &nbsp; &nbsp;
            <ListItemText
              primaryTypographyProps={{ fontSize: "14px" }}
              primary="Fechar"
            />
          </ListItem>
        </List>
      </Drawer>
    </>
  );
};
