import React, { useEffect, useState } from 'react';
import axios from "axios";
import { MenuSide } from '../../components/MenuSide';
import { Button, LinearProgress } from "@mui/material";
import { tokenUsuario, urlEnviarDisparos } from "../../utils/constants";
import { TableDisparosEnviados } from './components/TableDisparosEnviados';

export default function EnviarMensagensMassa() {

    const [atualizar, setAtualizar] = useState(0);
    const [textoEnviar, setTextoEnviar] = useState('Disparar mensagens');
    const [empresa, setEmpresa] = useState(null);
    const [loadingEnvio, setLoadingEnvio] = useState(false);
    const [contador, setContador] = useState(0); // tempo restante
    const [botaoDesativado, setBotaoDesativado] = useState(false);
    const [totalNaoEnviadas, setTotalNaoEnviadas] = useState(0);

    const url = window.location.href;
    const urlBase = new URL(url);

    // Timer que roda enquanto o contador está ativo
    useEffect(() => {
        if (contador > 0) {
            const intervalo = setInterval(() => {
                setContador(prev => prev - 1);
            }, 1000);

            return () => clearInterval(intervalo);
        } else {
            setBotaoDesativado(false);
            if (totalNaoEnviadas > 0) {
                setTextoEnviar('Disparar mensagens');
            }
        }
    }, [contador]);

    const enviarMensagens = async () => {
        setLoadingEnvio(true);
        setTextoEnviar('Enviando mensagens');
        setBotaoDesativado(true);
        setContador(60);

        try {
            const response = await axios.get(urlEnviarDisparos, {
                token: tokenUsuario,
                id: urlBase.searchParams.get('id'),
            });

            if (response.data.status === 'enviado') {
                enviarMensagens(); // recursivo, continua enquanto tiver envio
                setAtualizar(Math.random());
            } else {
                setAtualizar(Math.random());
            }

        } catch (error) {
            console.error('Erro ao enviar mensagens:', error);
        } finally {
            setLoadingEnvio(false);
        }
    };

    const buscarEnvios = (valor) => {
        setTotalNaoEnviadas(valor);
        if (valor === 0) {
            setTextoEnviar('Sem mensagens para enviar');
            setBotaoDesativado(true); // se não tem mais mensagens, desativa o botão
        }
    };

    const buscarEmpresa = (value) => {
        setEmpresa(value);
    };

    return (
        <div className='row'>
            <div className='col-sm-2'>
                <MenuSide />
            </div>
            <div className='col-sm-10'>
                <div className='card' style={{ margin: '30px' }}>
                    <div className='card-header'>
                        <div className='row'>
                            <div className='col-sm-6' style={{ fontWeight: 700, fontSize: '25px' }}>
                                {textoEnviar} - {empresa}
                            </div>
                        </div>
                    </div>

                    <div className='card-footer'>
                        <div className='row'>
                            <div className='col-sm-8'>
                                <br />
                                {loadingEnvio ? (
                                    <LinearProgress />
                                ) : null}
                            </div>
                            <div className='col-sm-4 text-end'>
                                {(textoEnviar === 'Sem mensagens para enviar') ? null : (
                                    <Button
                                        color="error"
                                        variant="contained"
                                        disabled={botaoDesativado}
                                        onClick={enviarMensagens}
                                        style={{
                                            width: 250,
                                            height: 40,
                                            borderRadius: '0.5rem',
                                            marginTop: '4px',
                                        }}
                                    >
                                        {botaoDesativado
                                            ? `Aguarde (${contador}s)`
                                            : textoEnviar}
                                    </Button>
                                )}
                                <br />
                            </div>
                        </div>
                    </div>
                </div>

                <TableDisparosEnviados
                    valorAtualiza={atualizar}
                    totalEnviar={buscarEnvios}
                    empresa={buscarEmpresa}
                />
            </div>
        </div>
    );
}
