import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useNavigate, createSearchParams } from "react-router-dom";
import { MenuSide } from '../../components/MenuSide';
import { Button } from "@mui/material";

import { urlInformacoesDisparos, tokenUsuario } from "../../utils/constants";


export default function DetalheDisparos() {

    const navigate = useNavigate();
    const [mensagem, setMensagem] = useState('');

    const url = window.location.href;
    const urlBase = new URL(url);
    const id = urlBase.searchParams.get('id');

    const informacoesContato = async () => {
        try {
            const response = await axios.post(urlInformacoesDisparos, {
                id: id,
                token: tokenUsuario,
            });
            return response.data;
        } catch (error) {
            return null;
        }
    };


    useEffect(() => {
        const run = async () => {
            const info = await informacoesContato();
            setMensagem(info.mensagem);
        }
        run();
    }, []);

    return (
        <>
            <div className='row'>
                <div className='col-sm-2'>
                    <MenuSide />
                </div>
                <div className='col-sm-10'>
                    <br /> <div className='card'
                        style={{ marginRight: '1%',  }}>
                        <div className='card-header'>
                            <div className='row'>
                                <div className='col-sm-3' style={{ fontWeight: 700, fontSize: '25px', }}>
                                    Detalhes da mensagem
                                </div>
                                <div className='col-sm-9 text-end'>
                                    <Button
                                        color={'error'}
                                        variant={'contained'}
                                        onClick={() => {
                                            navigate({
                                                pathname: "/disparos/alterar",
                                                search: createSearchParams({
                                                    id:id,
                                                }).toString(),
                                            });
                                        }}
                                        style={{
                                            width: 140,
                                            height: 40,
                                            borderRadius: '0.5rem',
                                            marginTop: '4px',
                                        }}
                                    >
                                        ALTERAR
                                    </Button>
                                    &nbsp;&nbsp;&nbsp;
                                    <Button
                                        color={'error'}
                                        variant={'contained'}
                                        onClick={() => {
                                            navigate({
                                                pathname: "/disparos/disparar_mensagens",
                                                search: createSearchParams({
                                                    id:id,
                                                }).toString(),
                                            });
                                        }}
                                        style={{
                                            width: 200,
                                            height: 40,
                                            borderRadius: '0.5rem',
                                            marginTop: '4px',
                                        }}
                                    >
                                        Enviar mensagem
                                    </Button>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-sm-6'>
                                    <div style={{
                                        backgroundColor: '#015C4B',
                                        fontWeight: 600,
                                        borderRadius: "0.5rem",
                                        margin: "5px",
                                        maxWidth: "100%",
                                        fontSize: "14px",
                                        paddingTop: "10px",
                                        paddingBottom: "10px",
                                        paddingLeft: "10px",
                                        paddingRight: "10px",
                                    }}>
                                        {mensagem}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}
