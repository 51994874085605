import React from "react";
import { Button } from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

function extrairNumeroENomeDoVCard(vCard) {
  // Expressões regulares para buscar o nome (FN) e o número de telefone (TEL)
  const regexNome = /FN:([^;\n]+)/;
  const regexTelefone = /TEL;waid=([^:]+):/;

  const nomeMatch = vCard.match(regexNome);
  const telefoneMatch = vCard.match(regexTelefone);

  if (nomeMatch && nomeMatch[1] && telefoneMatch && telefoneMatch[1]) {
    const nome = nomeMatch[1];
    const numero = telefoneMatch[1];
    return { nome, numero };
  } else {
    return null; // Caso não seja possível extrair as informações
  }
}

export const MensagemChat = ({
  mensagem,
  tipo,
  data,
  categoria,
  media,
  arquivo,
}) => {
  const estilo = {
    backgroundColor: tipo === 1 ? "#f44336" : "#ffffff",
    color: tipo === 1 ? "#ffffff" : "#000000",
    fontWeight: 600,
    borderRadius:
      tipo === 1 ? "0.7rem 0.7rem 1px 0.7rem" : " 0.7rem 0.7rem 0.7rem 1px",
    margin: "5px",
    maxWidth: "100%",
    alignSelf: tipo === 1 ? "flex-end" : "flex-start",
    fontSize: "14px",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
  };

  const dataMensagem = new Date(data);
  const horaFormatada = dataMensagem.toLocaleTimeString("pt-BR", {
    hour: "2-digit",
    minute: "2-digit",
  });

  const renderizarConteudo = () => {
    if (media === 1) {
      return (
        <span>
          Sistema: Por favor, verifique a mensagem no celular. Apenas textos são
          suportados!
        </span>
      );
    } else if (categoria === "video") {
      // Renderizar um player de vídeo com autoplay desativado
      return (
        <video controls autoPlay={false} width="100%">
          <source src={arquivo} type="video/mp4" />
          Seu navegador não suporta o elemento de vídeo.
        </video>
      );
    } else if (categoria === "image") {
      // Renderizar uma imagem
      return <img src={arquivo} alt="Imagem" style={{ maxWidth: "100%" }} />;
    } else if (categoria === "sticker") {
      // Renderizar uma imagem
      return <img src={arquivo} alt="Imagem" style={{ maxWidth: 100 }} />;
    } else if (categoria === "audio" || categoria === "ptt") {
      // Renderizar um player de áudio com autoplay desativado
      return (
        <>
          <audio controls autoPlay={false}>
            <source src={arquivo} type="audio/mpeg" />
            Seu navegador não suporta o elemento de áudio.
          </audio>
        </>
      );
    } else if (categoria === "document") {
      // Renderizar um botão de download para documentos
      return (
        <a href={arquivo} download>
          <Button
            style={{
              width: "80%",
              backgroundColor: "#DFF3ED",
              color: "#111B21",
              borderRadius: "0.5rem",
              height: "40px",
            }}
          >
            Download do Documento &nbsp;&nbsp;&nbsp;
            <CloudDownloadIcon />
          </Button>
        </a>
      );
    } else if (categoria === "vcard") {
      const contato = extrairNumeroENomeDoVCard(mensagem);
      return (
        <span>
          {contato.nome.replace("&#10", "")} : {contato.numero}
        </span>
      );
    } else if (categoria === "chat") {
      return <span>{mensagem}</span>;
    } else {
      return (
        <span>Mensagem não suportada no sistema. Verifique no celular</span>
      );
    }
  };

  return (
    <div className="card" style={estilo}>
      {renderizarConteudo()}

      <span
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          fontSize: "10px",
        }}
      >
        {horaFormatada}
      </span>
    </div>
  );
};
