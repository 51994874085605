/* eslint-disable no-unused-expressions */
import '@ionic/react/css/core.css';
export const limitarInput = (element, tamanho) => {
    let maximo = tamanho;
    if (element.value.length > maximo) {
        element.value = element.value.substr(0, maximo);
    }
};

export const showActionSheet = (header, options) => {
    let actionSheet = document.createElement("ion-action-sheet");
    actionSheet.header = header;
    actionSheet.buttons = options.map((option) => {
        return {
            text: option.text,
            role: option.role,
            handler: () => {
                option.handler();
            },
        };
    });

    document.body.appendChild(actionSheet);
    actionSheet.present();
};

export const loadingRequest = (mensagem, tempo) => {
    const loading = document.createElement("ion-loading");

    loading.cssClass = "my-custom-class";
    loading.message = mensagem;
    loading.duration = tempo;

    document.body.appendChild(loading);
    const presentPromise = loading.present();

    return {
        dismiss: () => {
            loading.dismiss();
        },
        presentPromise: presentPromise,
    };
};

export const toastNotificacao = (cor, icone, texto, tempo, textoBotao, funcao) => {
    const toast = document.createElement("ion-toast");

    toast.color = cor;
    toast.icon = icone;
    toast.message = texto;
    toast.mode = "ios";
    toast.duration = tempo;
    if (funcao) {
        toast.buttons = [
            {
                text: textoBotao,
                handler: () => {
                    funcao;
                    return;
                },
            },
        ];
    } else {
        toast.buttons = [
            {
                text: textoBotao,
                handler: () => {
                    return;
                },
            },
        ];
    }

    document.body.appendChild(toast);

    toast.present();
};

export const validarEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
}


export const CopiarTexto = async (mensagem, tempo, textoCopiar) => {

    navigator.clipboard.writeText(textoCopiar).then(
        async () => {
            const toast = await document.createElement("ion-toast");
            toast.color = "success";
            toast.mode = "ios";
            toast.icon = "checkmark-outline";
            toast.message = mensagem;
            toast.duration = tempo;
            toast.buttons = [
                {
                    text: "Ok",
                    role: "cancel",
                    handler: () => {
                        console.log("Alerta fechado");
                    },
                },
            ];

            document.body.appendChild(toast);
            await toast.present();
        },
        async () => {
            const toast = await document.createElement("ion-toast");
            toast.color = "danger";
            toast.icon = "alert-outline";
            toast.message =
                "Não foi possível copiar o texto do elemento selecionado!";
            toast.duration = tempo;
            toast.buttons = [
                {
                    text: "Ok",
                    role: "cancel",
                    handler: () => {
                        console.log("Alerta fechado");
                    },
                },
            ];

            document.body.appendChild(toast);
            await toast.present();
        }
    );
};

export const alertNotification = async (titulo, descricao, textoBotao, funcao) => {
    const alert = document.createElement("ion-alert");
    alert.header = titulo;
    alert.mode = "ios";
    alert.backdropDismiss = false;
    alert.message = descricao;
    alert.buttons = [
        {
            text: textoBotao,
            role: "confirm",
            handler: () => {
                funcao();
            },
        },
    ];
    document.body.appendChild(alert);
    await alert.present();
};

export const alertNotificationMultiple = async (
    titulo,
    descricao,
    textoBotao1,
    funcao1,
    textoBotao2,
    funcao2
) => {
    const alert = document.createElement("ion-alert");
    alert.header = titulo;
    alert.mode = "ios";
    alert.backdropDismiss = false;
    alert.message = descricao;
    alert.buttons = [
        {
            text: textoBotao1,
            handler: () => {
                funcao1();
            },
        },
        {
            text: textoBotao2,
            handler: () => {
                funcao2();
            },
        },
    ];
    document.body.appendChild(alert);
    await alert.present();
};

