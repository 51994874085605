import React, { useEffect, useState } from "react";
import { useNavigate, createSearchParams, useLocation } from "react-router-dom";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { urlListarAdministradores, tokenUsuario } from "../../../utils/constants";
import CustomPagination from "../../../components/PaginationTable";

export const TableDados = ({ nome }) => {
  const [contatos, setContatos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagina, setPagina] = useState(1);
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams2 = new URLSearchParams(location.search);

  const listarContatos = async () => {
    try {
      const response = await axios.post(
        `${urlListarAdministradores}?pagina=${pagina}`,
        {
          token: tokenUsuario,
          nome: nome,
        }
      );
      return response.data;
    } catch (error) {
      return null;
    }
  };

  const handlePageChange = (newPage) => {
    setPagina(newPage);
    setLoading(true);
    listarContatos(newPage)
      .then((lista) => {
        const contatosFiltrados = lista.filter((item) =>
          item.nome.toLowerCase().includes(nome.toLowerCase())
        );
        setContatos(contatosFiltrados);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const alterarContato = (event) => {
    navigate({
      pathname: "/admin/alterar",
      search: createSearchParams({
        id: event.row.id,
      }).toString(),
    });
  };

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const lista = await listarContatos(pagina);
      searchParams2.set("pagina", pagina);
      const novaURL = `${location.pathname}?${searchParams2.toString()}`;
      navigate(novaURL);
      setContatos(lista);
      setLoading(false);
    };
    run();
  }, [pagina]);

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const lista = await listarContatos();
      setContatos(lista);
      setLoading(false);
    };
    run();
  }, [nome]);

  const columns = [
    { field: "nome", headerName: "Nome", width: 355 },
  ];

  return (
    <>
      <div style={{ width: "100%" }}>
        {loading ? (
          <CircularProgress
            style={{ height: "20px", width: "20px" }}
            color="primary"
          />
        ) : (
          <DataGrid
            rows={contatos}
            columns={columns}
            loading={loading}
            disableSelectionOnClick
            disableColumnMenu
            disableColumnSelector
            disableRowSelectionOnClick
            rowHeight={40}
            headerHeight={40}
            autoHeight={true}
            onRowClick={alterarContato}
            components={{
              Pagination: (props) => (
                <CustomPagination {...props} onPageChange={handlePageChange} />
              ),
            }}
          
          />
        )}
      </div>
    </>
  );
};
