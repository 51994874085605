import React, { useState } from 'react';
import axios from 'axios';
import { MenuSide } from '../../components/MenuSide';
import { TextField, Button, Select, MenuItem, FormControl } from '@mui/material';
import { buttonWhiteForm } from '../../styles/style';
import { urlAlterarStatusPedidosData, tokenUsuario } from "../../utils/constants";

import { AlertCustom } from "../../components/AlertCustom";

export default function Avancado() {

    const [dataSelecionada, setDataSelecionada] = useState('');
    const [statusAtual, setStatusAtual] = useState('');
    const [novoStatus, setNovoStatus] = useState('');

    const [successAlert, setSuccessAlert] = useState(false);
    const [erroAlert, setErrorAlert] = useState(false);

    const statusTextArray = [
        { id: "1", nome: "Pendente - Em preparação" },
        { id: "2", nome: "Em andamento" },
        { id: "3", nome: "Aguardando confirmação de pagamento" },
        { id: "4", nome: "Finalizado" },
        { id: "5", nome: "Cancelado" },
        { id: "6", nome: "Reembolsado" },
        { id: "7", nome: "No carrinho" },
    ];

    const alterarPedidos = async () => {
        try {
            const response = await axios.post(urlAlterarStatusPedidosData, {
                token: tokenUsuario,
                statusAtual: statusAtual,
                novoStatus: novoStatus,
                dataSelecionada: dataSelecionada,
            }
            );
            if (response.data == 1) {
                setSuccessAlert(true);
            } else {
                setErrorAlert(true);
            }
        } catch (error) {
            setErrorAlert(true);
        }
    };

    return (
        <>
            <AlertCustom
                texto={"Informações alteradas com sucesso!"}
                color="success"
                isOpen={successAlert}
                setIsOpen={() => {
                    setSuccessAlert(false);
                }}
                tempo={2000}
            />
            <AlertCustom
                texto={"Não foi possível alteradas as informações!"}
                color="error"
                isOpen={erroAlert}
                setIsOpen={() => {
                    setErrorAlert(false);
                }}
                tempo={3000}
            />
            <div className='row'>
                <div className='col-sm-2'>
                    <MenuSide />
                </div>
                <div className='col-sm-10'>
                    <br />
                    <div className='card' style={{ marginRight: '1%' }}>
                        <div className='card-header'>
                            <div className='row'>
                                <div className='col-sm-10' style={{ fontWeight: 700, fontSize: '25px' }}>
                                    Alterar Pedidos Por Status/Data
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>

                            <div className='row'>
                                <div className='col-sm-4'>
                                    <h6>&nbsp;Data</h6>
                                    <FormControl fullWidth>
                                        <TextField
                                            value={dataSelecionada}
                                            onChange={(event) => {
                                                event.preventDefault();
                                                setDataSelecionada(event.target.value);
                                            }}
                                            type="date"
                                            InputProps={{
                                                sx: {
                                                    height: "39px",
                                                    borderRadius: '0.5rem',
                                                    width: '100%',
                                                    padding: '10px',
                                                }
                                            }} variant="outlined" />
                                    </FormControl>
                                </div>

                                <div className='col-sm-4'>
                                    <h6>&nbsp;Se o status for</h6>
                                    <FormControl fullWidth>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            style={{
                                                borderRadius: '0.5rem',
                                                height: "39px",
                                                marginLeft: "3px",
                                                marginRight: "3px",
                                            }}
                                            value={statusAtual}
                                            onChange={(event) => {
                                                event.preventDefault();
                                                setStatusAtual(event.target.value);
                                            }}
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        borderRadius: '0.5rem',
                                                    },
                                                },
                                            }}
                                        >
                                            {statusTextArray.map((item, index) => (
                                                <MenuItem
                                                    key={index}
                                                    value={item.id}
                                                >
                                                    {item.nome}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className='col-sm-4'>
                                    <h6>&nbsp;Altere para</h6>
                                    <FormControl fullWidth>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={novoStatus}
                                            onChange={(event) => {
                                                event.preventDefault();
                                                setNovoStatus(event.target.value);
                                            }}
                                            style={{
                                                borderRadius: '0.5rem',
                                                height: "39px",
                                                marginLeft: "3px",
                                                marginRight: "3px",
                                            }}
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        borderRadius: '0.5rem',
                                                    },
                                                },
                                            }}
                                        >
                                            {statusTextArray.map((item, index) => (
                                                <MenuItem
                                                    key={index}
                                                    value={item.id}
                                                >
                                                    {item.nome}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <br />
                            <div className='row'>
                                <div className='col-sm-10'></div>
                                <div className='col-sm-2'>
                                    <Button
                                        variant={'contained'}
                                        color={'error'}
                                        onClick={() => {
                                            alterarPedidos();
                                        }}
                                        style={buttonWhiteForm}>
                                        Alterar
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className='card-footer'>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
