import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useNavigate, } from "react-router-dom";
import { MenuSide } from '../../components/MenuSide';
import { Button, FormControl, TextField, Autocomplete } from "@mui/material";
import ButtonCustom from '../../components/ButtonCustom';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { urlAlterarClientes, urlInformacoesClientes, urlConsultaEmpresas, urlApagarClientes, tokenUsuario } from "../../utils/constants";

export default function AlterarProduto() {

    const navigate = useNavigate();
    const [nome, setNome] = useState('');
    const [codigo, setCodigo] = useState('');
    const [ddd, setDDD] = useState('');
    const [telefone, setTelefone] = useState('');
    const [doc, setDoc] = useState('');
    const [email, setEmail] = useState('');
    const [empresas, setEmpresas] = useState([]);
    const [empresa, setEmpresa] = useState(null);

    const url = window.location.href;
    const urlBase = new URL(url);

    const informacoesContato = async () => {
        try {
            const response = await axios.post(urlInformacoesClientes, {
                id: urlBase.searchParams.get('id'),
                token: tokenUsuario,
            });
            return response.data;
        } catch (error) {
            return null;
        }
    };

    const alterarInformacoes = async () => {
        try {
            const response = await axios.post(urlAlterarClientes, {
                nome: nome,
                codigo: codigo,
                ddd: ddd,
                telefone: telefone,
                doc: doc,
                email: email,
                empresa_id: empresa ? empresa.id : null,
                id: urlBase.searchParams.get('id'),
                token: tokenUsuario,
            });
            if (response.data == 1) {
                navigate({
                    pathname: "/clientes",
                });
            }
        } catch (error) {
            return null;
        }
    };

    const excluirInformacoes = async () => {
        try {
            const response = await axios.post(urlApagarClientes, {
                id: urlBase.searchParams.get('id'),
                token: tokenUsuario,
            });
            if (response.data == 1) {
                navigate({
                    pathname: "/clientes",
                });
            }
        } catch (error) {
            return null;
        }
    }

    useEffect(() => {
        const run = async () => {
            const info = await informacoesContato();
            setNome(info.nome);
            setCodigo(info.codigo);
            setDDD(info.ddd);
            setTelefone(info.telefone);
            setDoc(info.doc);
            setEmail(info.email);
            if (info.empresa_id) {
                setEmpresa({ id: info.empresa_id, nome: info.nome_empresa });
            } else {
                setEmpresa(null);
            }
        }
        run();
    }, []);

    const carregarClientes = async () => {
        try {
            const response = await axios.post(urlConsultaEmpresas, {
                token: tokenUsuario,
            })
            return response.data;
        } catch (error) {
            return null;
        }
    }

    const listarClientes = async () => {
        let clientesData = await carregarClientes();
        setEmpresas(clientesData);
    }

    useEffect(() => {
        listarClientes();
    }, []);

    return (
        <>
            <div className='row'>
                <div className='col-sm-2'>
                    <MenuSide />
                </div>
                
                <div className='col-sm-10'>
                    <br />
                    <div className='card' style={{ marginRight: '1%' }}>
                        <div className='card-header'>
                            <div className='row'>
                                <div className='col-sm-3' style={{ fontWeight: 700, fontSize: '25px', }}>
                                    Alterar cliente
                                </div>
                                <div className='col-sm-9 text-end'>
                                    <Button
                                        color={'error'}
                                        variant={'contained'}
                                        onClick={() => {
                                            excluirInformacoes();
                                        }}
                                        style={{
                                            color: '#111B21',
                                            width: 50,
                                            height: 39,
                                            borderRadius: '0.5rem',
                                            marginTop: '4px',
                                        }}
                                    >
                                        <DeleteForeverOutlinedIcon style={{ color: '#ffffff' }} />
                                    </Button>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='row'>
                                    <div className='col-sm-3'>
                                        <h6>Nome</h6>
                                        <FormControl fullWidth>
                                            <TextField
                                                placeholder='Nome'
                                                onChange={(event) => {
                                                    event.preventDefault();
                                                    setNome(event.target.value)
                                                }}
                                                value={nome}
                                                type="text"
                                                InputProps={{
                                                    sx: {
                                                        height: "39px",
                                                        borderRadius: '0.5rem',
                                                        width: '100%',
                                                        padding: '10px',
                                                    }
                                                }} variant="outlined" />
                                        </FormControl>
                                    </div>

                                    <div className='col-sm-3'>
                                        <h6>CPF/CNPJ</h6>
                                        <FormControl fullWidth>
                                            <TextField
                                                placeholder='CPF/CNPJ'
                                                onChange={(event) => {
                                                    event.preventDefault();
                                                    setDoc(event.target.value)
                                                }}
                                                value={doc}
                                                type="text"
                                                InputProps={{
                                                    sx: {
                                                        height: "39px",
                                                        borderRadius: '0.5rem',
                                                        width: '100%',
                                                        padding: '10px',
                                                    }
                                                }} variant="outlined" />
                                        </FormControl>
                                    </div>
                                    <div className='col-sm-3'>
                                        <h6>E-mail</h6>
                                        <FormControl fullWidth>
                                            <TextField
                                                placeholder='E-mail'
                                                onChange={(event) => {
                                                    event.preventDefault();
                                                    setEmail(event.target.value)
                                                }}
                                                value={email}
                                                type="text"
                                                InputProps={{
                                                    sx: {
                                                        height: "39px",
                                                        borderRadius: '0.5rem',
                                                        width: '100%',
                                                        padding: '10px',
                                                    }
                                                }} variant="outlined" />
                                        </FormControl>
                                    </div>
                                    <div className='col-sm-12'>&nbsp;</div>
                                    <div className='col-sm-3'>
                                        <h6>Código do País</h6>
                                        <FormControl fullWidth>
                                            <TextField
                                                placeholder='Código do País'
                                                onChange={(event) => {
                                                    event.preventDefault();
                                                    setCodigo(event.target.value)
                                                }}
                                                value={codigo}
                                                type="text"
                                                InputProps={{
                                                    sx: {
                                                        height: "39px",
                                                        borderRadius: '0.5rem',
                                                        width: '100%',
                                                        padding: '10px',
                                                    }
                                                }} variant="outlined" />
                                        </FormControl>
                                    </div>
                                    <div className='col-sm-3'>
                                        <h6>DDD</h6>
                                        <FormControl fullWidth>
                                            <TextField
                                                placeholder='DDD'
                                                onChange={(event) => {
                                                    event.preventDefault();
                                                    setDDD(event.target.value)
                                                }}
                                                value={ddd}
                                                type="text"
                                                InputProps={{
                                                    sx: {
                                                        height: "39px",
                                                        borderRadius: '0.5rem',
                                                        width: '100%',
                                                        padding: '10px',
                                                    }
                                                }} variant="outlined" />
                                        </FormControl>
                                    </div>
                                    <div className='col-sm-3'>
                                        <h6>Número</h6>
                                        <FormControl fullWidth>
                                            <TextField
                                                placeholder='Número'
                                                onChange={(event) => {
                                                    event.preventDefault();
                                                    setTelefone(event.target.value)
                                                }}
                                                value={telefone}
                                                type="text"
                                                InputProps={{
                                                    sx: {
                                                        height: "39px",
                                                        borderRadius: '0.5rem',
                                                        width: '100%',
                                                        padding: '10px',
                                                    }
                                                }} variant="outlined" />
                                        </FormControl>
                                    </div>
                                    <div className='col-sm-3'>
                                        <h6>Empresa</h6>
                                        <Autocomplete
                                            disablePortal
                                            options={empresas}
                                            getOptionLabel={(option) => `${option.nome}`}
                                            value={empresa}
                                            onChange={(event, newValue) => {
                                                setEmpresa(newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    sx={{
                                                        '& .MuiInputBase-input': {},
                                                        '& .MuiInputBase-root': {
                                                            height: "39px",
                                                            borderRadius: '0.5rem',
                                                        },
                                                        '& .MuiOutlinedInput-root': {
                                                            height: "39px",
                                                            padding: '10px',
                                                        },
                                                        width: '100%',
                                                    }}
                                                />
                                            )}
                                            renderOption={(props, option) => (
                                                <li {...props} key={option.id}>
                                                    {option.nome}
                                                </li>
                                            )}
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='card-footer'>
                            <div className='row'>
                                <div className='col-sm-3'></div>
                                <div className='col-sm-7'></div>
                                <div className='col-sm-2 text-end'>
                                    <ButtonCustom
                                        onClick={() => {
                                            alterarInformacoes();
                                        }}
                                        color={"error"}
                                        texto={"Confirmar"}
                                        icon={null}
                                    />
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}
