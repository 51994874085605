import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useNavigate, } from "react-router-dom";
import { MenuSide } from '../../components/MenuSide';
import { Button, FormControl, TextField } from "@mui/material";
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { urlAlterarEnderecosNatendidos, urlInformacoesEnderecosNatendidos, urlApagarEnderecosNatendidos, tokenUsuario } from "../../utils/constants";
import ButtonCustom from '../../components/ButtonCustom';
import SelectCustom from '../../components/SelectCustom';

export default function AlterarProduto() {

    const navigate = useNavigate();
    const [endereco, setEndereco] = useState('');
    const [cep, setCep] = useState('');
    const [tipo, setTipo] = useState('');

    const url = window.location.href;
    const urlBase = new URL(url);

    const informacoesContato = async () => {
        try {
            const response = await axios.post(urlInformacoesEnderecosNatendidos, {
                id: urlBase.searchParams.get('id'),
                token: tokenUsuario,
            });
            return response.data;
        } catch (error) {
            return null;
        }
    };

    const alterarInformacoes = async () => {
        try {
            const response = await axios.post(urlAlterarEnderecosNatendidos, {
                endereco: endereco,
                tipo: tipo,
                cep: cep,
                id: urlBase.searchParams.get('id'),
                token: tokenUsuario,
            });
            if (response.data == 1) {
                navigate({
                    pathname: "/enderecos_nao_atendidos",
                });
            }
        } catch (error) {
            return null;
        }
    };

    const excluirInformacoes = async () => {
        try {
            const response = await axios.post(urlApagarEnderecosNatendidos, {
                id: urlBase.searchParams.get('id'),
                token: tokenUsuario,
            });
            if (response.data == 1) {
                navigate({
                    pathname: "/enderecos_nao_atendidos",
                });
            }
        } catch (error) {
            return null;
        }
    }

    useEffect(() => {
        const run = async () => {
            const info = await informacoesContato();
            setEndereco(info.endereco);
            setTipo(info.tipo);
            setCep(info.cep);
        }
        run();
    }, []);

    const consultarEndereco = async () => {
        try {
            const response = await axios.get(
                `https://viacep.com.br/ws/${cep}/json/`,
                {}
            );
            return response.data;
        } catch (error) {
            return [];
        }
    };

    const carregarEndereco = async () => {
        const response = await consultarEndereco();
        if (tipo == '1') {
            setEndereco(response.logradouro);
        } else {
            setEndereco(response.bairro);
        }
    }
    useEffect(() => {
        console.log(cep)
        if (cep.length === 8) {
            carregarEndereco();
        }
    }, [cep, tipo]);

    const tipos = [{
        id: 1,
        nome: 'Rua'
    },
    {
        id: 2,
        nome: 'Bairro'
    }];

    return (
        <>
            <div className='row'>
                <div className='col-sm-2'>
                    <MenuSide />
                </div>
                <div className='col-sm-10'>
                    <br />
                    <div className='card' style={{ marginRight: '1%' }}>
                        <div className='card-header'>
                            <div className='row'>
                                <div className='col-sm-6' style={{ fontWeight: 700, fontSize: '25px', }}>
                                    Alterar endereço não atendido
                                </div>
                                <div className='col-sm-6 text-end'>
                                    <Button
                                        color={'error'}
                                        variant={'contained'}
                                        onClick={() => {
                                            excluirInformacoes();
                                        }}
                                        style={{
                                            color: '#111B21',
                                            width: 50,
                                            height: 39,
                                            borderRadius: '0.5rem',
                                            marginTop: '4px',
                                        }}
                                    >
                                        <DeleteForeverOutlinedIcon style={{ color: '#ffffff' }} />
                                    </Button>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-sm-4'>
                                    <h6>CEP</h6>
                                    <FormControl fullWidth>
                                        <TextField
                                            placeholder='CEP'
                                            onChange={(event) => {
                                                event.preventDefault();
                                                setCep(event.target.value)
                                            }}
                                            value={cep}
                                            type="tel"
                                            InputProps={{
                                                sx: {
                                                    height: "39px",
                                                    borderRadius: '0.5rem',
                                                    width: '100%',
                                                    padding: '10px',
                                                }
                                            }} variant="outlined" />
                                    </FormControl>
                                </div>
                                <div className='col-sm-4'>
                                    <h6>Endereço</h6>
                                    <FormControl fullWidth>
                                        <TextField
                                            placeholder='Endereço'
                                            onChange={(event) => {
                                                event.preventDefault();
                                                setEndereco(event.target.value)
                                            }}
                                            value={endereco}
                                            type="text"
                                            InputProps={{
                                                sx: {
                                                    height: "39px",
                                                    borderRadius: '0.5rem',
                                                    width: '100%',
                                                    padding: '10px',
                                                }
                                            }} variant="outlined" />
                                    </FormControl>
                                </div>

                                <div className='col-sm-3'>
                                    <h6>Tipo</h6>
                                    <SelectCustom
                                        value={tipo}
                                        setValue={(event) => {
                                            event.preventDefault();
                                            setTipo(event.target.value);
                                        }}
                                        itens={tipos}
                                        valueItem={"id"}
                                        nameItem={"nome"}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='card-footer'>
                            <div className='row'>
                                <div className='col-sm-3'></div>
                                <div className='col-sm-7'></div>
                                <div className='col-sm-2 text-end'>
                                    <ButtonCustom
                                        onClick={() => {
                                            alterarInformacoes();
                                        }}
                                        color={"error"}
                                        texto={"Confirmar"}
                                        icon={null}
                                    />
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}
