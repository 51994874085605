import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import {
  urlGraficoContatosEmpresas,
  tokenUsuario,
} from "../../utils/constants";
import Chart from "chart.js/auto";
import { CircularProgress } from "@mui/material";
import gradientsData from "./gradients.json";

function formatarComoReal(numero) {
  return numero.toLocaleString("pt-BR");
}

export default function GraficoContatosEmpresas({ ano }) {
  const chartRef = useRef(null);
  const [chart, setChart] = useState(null);
  const [loading, setLoading] = useState(false);
  const [totalSoma, setTotalSoma] = useState(0);

  const gradientMap = gradientsData.map((obj) => obj.colors[1]);

  const listaFormaPagamento = async () => {
    setLoading(false);
    try {
      const response = await axios.post(urlGraficoContatosEmpresas, {
        data: ano,
        token: tokenUsuario,
      });
      if (response.data) {
        const labels = response.data.map((item) => item.nome_empresa);
        const data = response.data.map((item) => item.total);
        const backgroundColors = gradientMap;
        const somaTotal = response.data.reduce(
          (acc, item) => acc + Number(item.total),
          0
        );
        setTotalSoma(somaTotal);
        updateChart(labels, data, backgroundColors);
      } else {
        throw new Error("No data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const updateChart = (labels, data, backgroundColors) => {
    if (chart) {
      chart.destroy();
    }

    const ctx = chartRef.current.getContext("2d");
    const newChart = new Chart(ctx, {
      type: "doughnut",
      data: {
        labels: labels,
        datasets: [
          {
            label: "Total de contatos",
            data: data,
            backgroundColor: backgroundColors,
            borderColor: "transparent",
          },
        ],
      },
      options: {
        //indexAxis: "x",
        cutout: 130,
        scales: {
          x: {
            beginAtZero: true,
            grid: {
              display: false,
            },
            ticks: {
              display: false,
            },
          },
        },
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });

    setChart(newChart);
  };

  useEffect(() => {
    listaFormaPagamento();
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [ano]);

  return (
    <>
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress
            style={{ height: "20px", width: "20px" }}
            color="primary"
          />
        </div>
      ) : (
        <div style={{ position: "relative" }}>
          <canvas
            ref={chartRef}
            style={{ width: "100%", height: "auto" }}
          ></canvas>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: 700,
              fontSize: "30px",
            }}
          >
            <span>{formatarComoReal(totalSoma)}</span>
          </div>
        </div>
      )}
    </>
  );
}
