/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Card, Modal, Box, IconButton } from "@mui/material";
import InputCustom from "../../../components/InputCustom";
import ButtonCustom from "../../../components/ButtonCustom";
import TextAreaCustom from "../../../components/TextAreaCustom";
import CloseIcon from "@mui/icons-material/Close";
import { urlRecusarPedido, tokenUsuario } from "../../../utils/constants";
import axios from "axios";
import dayjs from "dayjs";
import { toastNotificacao, alertNotificationMultiple } from "../../../utils/functions";
import { useEffect } from "react";

export const ModalRecusarPedido = ({ isOpen, setIsOpen }) => {
  const [pedido, setPedido] = useState("");
  const [motivo, setMotivo] = useState("");
  const [confirmar, setConfirmar] = useState(0);

  const consultaRecusaPedido = async (confirmarP) => {
    try {
      const response = await axios.post(urlRecusarPedido, {
        token: tokenUsuario,
        id: pedido,
        motivo: motivo,
        confirmar: confirmarP ? confirmarP : confirmar,
      });
      return response.data;
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    const run =async () =>{
      if (confirmar === 1) {
        await consultaRecusaPedido();
      }
    }
    run();
  }, [confirmar]);

  const recusarPedido = async () => {
    const response = await consultaRecusaPedido();
    if (response.status == "informacoes") {
      const mensagemReponse = `Recusar Pedido Nº ${pedido} feito por ${response.cliente
        } 
      no valor de R$ ${response.valor} em ${dayjs(response.data).format(
          "DD/MM/YYYY HH:mm"
        )}`;
      const funcao1 = async () => {
        setConfirmar(0);
        const response = await consultaRecusaPedido(1);
        if (response.status == "recusado") {
          toastNotificacao('success', 'checkmark', 'Pedido recusado com sucesso', 3000, 'Ok', () => { return });
          setTimeout(() => {
            setPedido("");
            setMotivo("");
            setConfirmar(0);
          }, 2000);
        }
      }

      const funcao2 = () => {
        return;
      }
      alertNotificationMultiple(
        'Atenção',
        mensagemReponse,
        'Sim',
        funcao1,
        'Não',
        funcao2
      );
    }

  };

  return (
    <>
      <div>
        <Modal open={isOpen} onClose={setIsOpen}>
          <Box style={{ padding: "4% 37% 10% 37%" }}>
            <Card
              style={{
                maxHeight: "85vh",
                minHeight: "85vh",
                overflowY: "auto",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <IconButton variant="contained" onClick={setIsOpen}>
                  <CloseIcon
                    style={{
                      margin: "10px",
                    }}
                  />
                </IconButton>
              </div>
              <div className="container">
                <InputCustom
                  value={pedido}
                  setValue={(event) => {
                    event.preventDefault();
                    setPedido(event.target.value);
                  }}
                  type={"tel"}
                  placeHolder={"Informe o número do pedido"}
                />
                <br />
                <br />
                <TextAreaCustom
                  value={motivo}
                  setValue={(event) => {
                    event.preventDefault();
                    setMotivo(event.target.value);
                  }}
                  placeHolder={"Informe um motivo"}
                />
                <br /> <br />
                <ButtonCustom
                  onClick={() => {
                    recusarPedido();
                  }}
                  color={'success'}
                  texto={'Continuar'}
                  icon={null}
                />
              </div>
            </Card>
          </Box>
        </Modal>
      </div>
    </>
  );
};
