import React from "react";
import { Button } from "@mui/material";

export const AlertAtendimentosEspera = ({ numero }) => {
  const hasAguardandoAtendimento = numero > 0;

  return (
    <>
      {hasAguardandoAtendimento > 0 ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "5%",
            }}
          >
            <Button
              variant="contained"
              color="error"
              style={{
                animation: hasAguardandoAtendimento
                  ? "pulse 600ms infinite"
                  : "none",
                borderRadius: "0.5rem",
                marginLeft: "13px",
                marginRight: "13px",
                width: "100%",
              }}
            >
              {numero} aguardando atendimento
            </Button>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
