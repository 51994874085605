import React from "react";
import { MenuSide } from "../../components/MenuSide";

export default function NotFound() {
  return (
    <>
      <div className="row">
        <div className="col-sm-2">
          <MenuSide />
        </div>
        <div className="col-sm-10">
          <br />
          <div className="card" style={{ marginRight: 12 }}>
            <div className="card-header">
              <div className="row">
                <div
                  className="col-sm-3"
                  style={{ fontWeight: 700, fontSize: "25px" }}
                >
                  Rota não localizada
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
