import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import {
  urlGraficoMarmitasFormaPagamentoContagem,
  tokenUsuario,
} from "../../utils/constants";
import Chart from "chart.js/auto";
import { CircularProgress } from "@mui/material";

function formatarComoReal(numero) {
  return numero.toLocaleString("pt-BR");
}

export default function GraficoMarmitasFormaPagamento({ ano }) {
  const chartRef = useRef(null);
  const [chart, setChart] = useState(null);
  const [loading, setLoading] = useState(false);
  const [totalSoma, setTotalSoma] = useState(0);

  const generateRandomColor = () => {
    return `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(
      Math.random() * 255
    )}, ${Math.floor(Math.random() * 255)}, 0.6)`;
  };

  const updateChart = (labels, data, backgroundColors) => {
    if (chart) {
      chart.destroy();
    }

    const ctx = chartRef.current.getContext("2d");
    const newChart = new Chart(ctx, {
      type: "doughnut",
      data: {
        labels: labels,
        datasets: [
          {
            label: "Número de Marmitas",
            data: data,
            backgroundColor: backgroundColors,
          },
        ],
      },
      options: {
        indexAxis: "y",
        cutout: 130,
        scales: {
          x: {
            beginAtZero: true,
            grid: {
              display: false,
            },
            ticks: {
              display: false,
            },
          },
        },
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });

    setChart(newChart);
  };

  const listarItens = async () => {
    try {
      const response = await axios.post(
        urlGraficoMarmitasFormaPagamentoContagem,
        {
          data: ano,
          token: tokenUsuario,
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const carregarItens = async () => {
    const itens = await listarItens();
    const labels = itens.map((item) => item.nome_empresa);
    const data = itens.map((item) => item.total);
    const backgroundColors = data.map(() => generateRandomColor());
    const somaTotal = itens.reduce((acc, item) => acc + Number(item.total), 0);
    setTotalSoma(somaTotal);
    updateChart(labels, data, backgroundColors);
  };
  useEffect(() => {
    carregarItens();
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [ano]);

  return (
    <>
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress
            style={{ height: "20px", width: "20px" }}
            color="primary"
          />
        </div>
      ) : (
        <div style={{ position: "relative" }}>
          <canvas
            ref={chartRef}
            style={{ width: "100%", height: "auto" }}
          ></canvas>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: 700,
              fontSize: "30px",
            }}
          >
            <span>{formatarComoReal(totalSoma)}</span>
          </div>
        </div>
      )}
    </>
  );
}
