/* eslint-disable no-unused-vars */
/* eslint-disable no-prototype-builtins */

import React, { useState, useEffect } from "react";
import { Card, Modal, Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ButtonCustom from "../../../components/ButtonCustom";
import InputCustom from "../../../components/InputCustom";
import {
  tokenUsuario,
  urlInformacoesProdutos,
  urlConsultaCategorias,
  urlConsultaIngredientes,
  urlAdicionarPedidos,
} from "../../../utils/constants";
import axios from "axios";
import { Typography } from "@mui/material";

//Accordion
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
//Accordion

export const ModalProduto = ({
  isOpen,
  setIsOpen,
  id,
  userId,
  carregarPedidoLancamento,
}) => {
  const [produtos, setProdutos] = useState([]);
  const [ingredientes, setIngredientes] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [idItem, setIdItem] = useState(id);
  const [qntProduto, setQntProduto] = useState(1);
  const clienteLocal = JSON.parse(localStorage.getItem("cliente_pedido"));

  const adicionarProduto = () => {
    setQntProduto(qntProduto + 1);
  };

  const subtrairProduto = () => {
    if (qntProduto > 0) {
      setQntProduto(qntProduto - 1);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setQntProduto(1);
  };

  const buscarIngredientes = (array) => {
    return array.filter(
      (item) => item.hasOwnProperty("quantidade") && item.quantidade > 0
    );
  };

  const adicionarAoCarrinho = async () => {
    try {
      const response = await axios.post(urlAdicionarPedidos, {
        user_id: clienteLocal.id,
        produto_id: id,
        qnt: qntProduto,
        ingredientes: buscarIngredientes(ingredientes),
        token: tokenUsuario,
      });
      if (response.data == 1) {
        setQntProduto(1);
        setIsOpen();
        carregarPedidoLancamento();
      }
    } catch (error) {
      return null;
    }
  };

  const informacoesProduto = async () => {
    try {
      const response = await axios.post(urlInformacoesProdutos, {
        id: id,
        token: tokenUsuario,
      });
      return response.data.dados;
    } catch (error) {
      return null;
    }
  };

  const consultarCategorias = async () => {
    try {
      const response = await axios.post(urlConsultaCategorias, {
        token: tokenUsuario,
      });
      return response.data;
    } catch (error) {
      return null;
    }
  };

  const consultarIngredientes = async () => {
    try {
      const response = await axios.post(urlConsultaIngredientes, {
        token: tokenUsuario,
      });
      return response.data;
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    const run = async () => {
      let produtosData = await informacoesProduto();
      let ingredientesData = await consultarIngredientes();
      let categoriasData = await consultarCategorias();
      setProdutos(produtosData);
      setCategorias(categoriasData);
      setIngredientes(ingredientesData);
    };
    run();
  }, [id, idItem, isOpen]);

  const setQuantidadeIngrediente = (index, quantidade) => {
    setIngredientes((currentIngredientes) =>
      currentIngredientes.map((ingrediente, ingredienteIndex) =>
        ingredienteIndex === index
          ? { ...ingrediente, quantidade: Math.max(0, quantidade) }
          : ingrediente
      )
    );
  };

  const adicionarIngrediente = (id) => {
    setIngredientes((currentIngredientes) =>
      currentIngredientes.map((ingrediente) =>
        ingrediente.id === id
          ? { ...ingrediente, quantidade: (ingrediente.quantidade || 0) + 1 }
          : ingrediente
      )
    );
  };

  const subtrairIngrediente = (id) => {
    setIngredientes((currentIngredientes) =>
      currentIngredientes.map((ingrediente) =>
        ingrediente.id === id
          ? {
              ...ingrediente,
              quantidade: Math.max(0, (ingrediente.quantidade || 0) - 1),
            }
          : ingrediente
      )
    );
  };

  useEffect(() => {}, [ingredientes]);

  return (
    <>
      <div>
        <Modal open={isOpen} onClose={setIsOpen}>
          <Box style={{ padding: "4% 4% 4% 4%" }}>
            <Card
              style={{
                maxHeight: "85vh",
                minHeight: "85vh",
                overflowY: "auto",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <IconButton onClick={handleClose}>
                  <CloseIcon
                    style={{
                      margin: "10px",
                    }}
                  />
                </IconButton>
              </div>
              <>
                <div style={{ margin: 15 }}>
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="card" style={{ padding: 20 }}>
                        <h4>{produtos.nome}</h4>
                        <div className="row">
                          <div className="col-sm-6">
                            <InputCustom
                              value={qntProduto}
                              setValue={(event) => {
                                event.preventDefault();
                                setQntProduto(event.target.value);
                              }}
                              type={"number"}
                            />
                          </div>
                          <div className="col-sm-6">
                            <div style={{ display: "flex" }}>
                              <div>
                                <ButtonCustom
                                  onClick={adicionarProduto}
                                  color={"error"}
                                  texto={"+"}
                                  icon={null}
                                />
                              </div>
                              &nbsp; &nbsp;
                              <div>
                                <ButtonCustom
                                  onClick={subtrairProduto}
                                  color={"error"}
                                  texto={"-"}
                                  icon={null}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <br /> <br />
                        <ButtonCustom
                          onClick={() => {
                            adicionarAoCarrinho();
                          }}
                          color={"error"}
                          texto={"Adicionar ao carrinho"}
                          icon={null}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      {categorias.map((categoria, categoriaIndex) => (
                        <div key={categoriaIndex}>
                          <Accordion disableGutters>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id={`categoria${categoria.id}`}
                            >
                              <Typography>{categoria.nome}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              {ingredientes
                                .filter(
                                  (ingrediente) =>
                                    ingrediente.categoria == categoria.id
                                )
                                .map((ingrediente, ingredienteIndex) => (
                                  <div key={ingredienteIndex} className="row">
                                    <div className="col-sm-4">
                                      {ingrediente.nome}
                                    </div>
                                    <div className="col-sm-2">
                                      <InputCustom
                                        value={ingrediente.quantidade || ""}
                                        setValue={(event) => {
                                          event.preventDefault();
                                          const newQuantity = parseInt(
                                            event.target.value,
                                            10
                                          );
                                          setQuantidadeIngrediente(
                                            ingredienteIndex,
                                            isNaN(newQuantity) ? 0 : newQuantity
                                          );
                                        }}
                                        type={"number"}
                                      />
                                    </div>
                                    <div className="col-sm-4">
                                      <div style={{ display: "flex" }}>
                                        <div>
                                          <ButtonCustom
                                            onClick={() => {
                                              adicionarIngrediente(
                                                ingrediente.id
                                              );
                                            }}
                                            color={"error"}
                                            texto={"+"}
                                            icon={null}
                                          />
                                        </div>
                                        &nbsp; &nbsp;
                                        <div>
                                          <ButtonCustom
                                            onClick={() => {
                                              subtrairIngrediente(
                                                ingrediente.id
                                              );
                                            }}
                                            color={"error"}
                                            texto={"-"}
                                            icon={null}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="row">&nbsp;</div>
                  <div className="row">&nbsp;</div>
                </div>
              </>
            </Card>
          </Box>
        </Modal>
      </div>
    </>
  );
};
