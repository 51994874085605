import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Modal, Box, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import { ModalDialog } from "../components/ModalDialog";
import {
  urlAtendentes,
  urlTransferirAtendimento,
  tokenUsuario,
} from "../../../utils/constants";
import axios from "axios";
import { buttonWhiteForm } from "../../../styles/style";
import { AlertCustom } from "../../../components/AlertCustom";
import SelectCustom from "../../../components/SelectCustom";
export const ModalTransferirAtendimento = ({
  isOpen,
  setIsOpen,
  id,
  fecharAtendimento,
}) => {
  let navigate = useNavigate();

  const [atendentes, setAtendentes] = useState([]);
  const [statusDialog, setStatusDialog] = useState(false);
  const [atendente, setAtendente] = useState("selecione");
  const [successAlert, setSuccessAlert] = useState(false);
  const [erroAlert, setErrorAlert] = useState(false);

  const altererarAtendente = (event) => {
    event.preventDefault();
    setAtendente(event.target.value);
  };

  const carregarAtendentes = async () => {
    try {
      const response = await axios.post(urlAtendentes, {
        token: tokenUsuario,
      });
      return response.data;
    } catch (error) {
      return null;
    }
  };

  const transferir = async () => {
    try {
      const response = await axios.post(urlTransferirAtendimento, {
        responsavel: atendente,
        id: id,
        token: tokenUsuario,
      });
      if (response.data.status == "transferido") {
        setIsOpen();
        setSuccessAlert(true);
        fecharAtendimento();
        navigate({
          pathname: "/chat",
        });
      } else {
        setErrorAlert(true);
      }
    } catch (error) {
      return null;
    }
  };

  const fecharSucessAlert = () => {
    setSuccessAlert(false);
  };

  const fechaErrorAlert = () => {
    setErrorAlert(false);
  };

  useEffect(() => {
    const run = async () => {
      let atendentesData = await carregarAtendentes();
      setAtendentes(atendentesData);
    };
    run();
  }, []);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div>
        <AlertCustom
          texto={"Atendimento transferido com sucesso"}
          color="success"
          isOpen={successAlert}
          setIsOpen={fecharSucessAlert}
          tempo={2000}
        />
        <AlertCustom
          texto={" Não foi possível transferir o atendimento!"}
          color="error"
          isOpen={erroAlert}
          setIsOpen={fechaErrorAlert}
          tempo={3000}
        />

        <Modal open={isOpen} onClose={setIsOpen}>
          <Box style={{ padding: "4% 37% 10% 37%" }}>
            <Card
              style={{
                maxHeight: "85vh",
                minHeight: "85vh",
                overflowY: "auto",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <IconButton onClick={handleClose}>
                  <CloseIcon
                    style={{
                      margin: "10px",
                    }}
                  />
                </IconButton>
              </div>
              <>
                <ModalDialog
                  isOpen={statusDialog}
                  setIsOpen={setStatusDialog}
                />
                <div style={{ margin: "20px" }}>
                  <h5 style={{ textAlign: "center", color: "#fff" }}>
                    Atendentes disponíveis
                  </h5>
                  <div className="row">&nbsp;</div>
                  <SelectCustom
                    value={atendente}
                    setValue={altererarAtendente}
                    itens={atendentes}
                    valueItem={"id"}
                    nameItem={"nome"}
                  />

                  <div className="row">&nbsp;</div>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => {
                      transferir();
                    }}
                    style={buttonWhiteForm}
                  >
                    Transferir &nbsp;&nbsp;&nbsp;
                    <SyncAltIcon />
                  </Button>
                </div>
              </>
            </Card>
          </Box>
        </Modal>
      </div>
    </>
  );
};
