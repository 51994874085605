import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import {
  urlGraficoAtendimentoHorarios,
  tokenUsuario,
} from "../../utils/constants";
import Chart from "chart.js/auto";
import { CircularProgress } from "@mui/material";

export default function GraficosAtendimentosHorarios({ ano }) {
  const chartRef = useRef(null);
  const [chart, setChart] = useState(null);
  const [loading, setLoading] = useState(false);

  const listaEmpresas = async () => {
    setLoading(false);
    try {
      const response = await axios.post(urlGraficoAtendimentoHorarios, {
        data: ano,
        token: tokenUsuario,
      });
      if (response.data) {
        const cores = [
          "#FC7079",
          "#00C3B3",
          "#838EFC",
          "#FF6600",
          "#00A781",
          "#1A50FE",
          "#F8D45F",
          "#F1639F",
          "#2A72B4",
          "#30C6F8",
          "#3D2989",
        ];
        const labels = response.data.map((item) => item.hora_formatada);
        const data = response.data.map((item) => item.quantidade);
        const backgroundColors = cores;

        updateChart(labels, data, backgroundColors);
      } else {
        throw new Error("No data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const updateChart = (labels, data) => {
    if (chart) {
      chart.destroy();
    }

    const ctx = chartRef.current.getContext("2d");
    const newChart = new Chart(ctx, {
      type: "line",
      data: {
        labels: labels,
        datasets: [
          {
            label: "Número de mensagens",
            data: data,
            //backgroundColor: backgroundColors,
            borderColor: "#F16100",
            borderWidth: 8,
          },
        ],
      },
      options: {
        elements: {
          point: {
            pointBorderWidth: 2,
            radius: 5,
            pointStyle: "circle",
            pointBackgroundColor: "#FF9C2D",
          },
          line: {
            tension: 0.4, // Valores entre 0 (linha reta) e 1 (muito curva)
          },
        },
        scales: {
          x: {
            beginAtZero: true,
            grid: {
              display: false,
            },
            ticks: {
              display: true,
              color: "#FFFFFF",
            },
          },
          y: {
            beginAtZero: true,
            grid: {
              display: false,
            },
            ticks: {
              display: true,
              color: "#FFFFFF",
            },
          },
        },
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });

    setChart(newChart);
  };

  useEffect(() => {
    listaEmpresas();
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [ano]);

  return (
    <>
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress
            style={{ height: "20px", width: "20px" }}
            color="primary"
          />
        </div>
      ) : (
        <canvas ref={chartRef} style={{ maxHeight: "400px" }}></canvas>
      )}
    </>
  );
}
