/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import axios from "axios";
import { MenuSide } from '../../components/MenuSide';
import { Button, Autocomplete, TextField, Input, Chip, Alert } from "@mui/material";
import { ItemBuscaProduto } from './components/ItemBuscaProduto';
import { ModalProduto } from './components/ModalProduto';
import { urlRemoverItemCarrinho, urlConsultaFormasPagamento, urlFinalizarPedido, urlLimparCarrinho, urlConsultaBuscaClientes, urlConsultaBuscaProdutos, urlDadosPedidoLancamento, urlPedidoDataClienteExiste, tokenUsuario } from "../../utils/constants";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { AlertCustom } from "../../components/AlertCustom";
import { ModalDialog } from '../../components/DialogCustom';

export default function AdicionarPedidos() {

    const url = window.location.href;
    const urlBase = new URL(url);
    const id = urlBase.searchParams.get('id');
    const clienteLocal = JSON.parse(localStorage.getItem('cliente_pedido'));
    const [produto, setProduto] = useState('');
    const [clientes, setClientes] = useState([]);
    const [cliente, setCliente] = useState(null);
    const [clienteSelecionado, setClienteSelecionado] = useState([]);
    const [produtos, setProdutos] = useState([]);
    const [produtoSelecionado, setProdutoSelecionado] = useState('');
    const [modalProduto, setModalProduto] = useState(false);
    const [formaPagamento, setFormaPagamento] = useState(null);
    const [formasPagamento, setFormasPagamento] = useState([]);

    const [pedidosL, setPedidosL] = useState([]);
    const [itensL, setItensL] = useState([]);
    const [ingredientesL, setIngredientesL] = useState([]);
    const [totalSoma, setTotalSoma] = useState(0);

    const [successAlert, setSuccessAlert] = useState(false);
    const [erroAlert, setErrorAlert] = useState(false);

    const fecharModalProduto = () => {
        setModalProduto(false);
    }

    const pedidosClienteData = async () => {
        try {
            const response = await axios.post(urlPedidoDataClienteExiste, {
                token: tokenUsuario,
                user_id: cliente.id,
            })
            let status = response.data.status;
            let valor = response.data.total_pedidos;
            if(status) {
                let msg = `Já existe um pedido lançado para esse 
                cliente na data atual no valor de R$ ${valor}. Deseja continuar?`;
                setMsgPedidosClienteData(msg);
                setModalPedidosClienteData(true);
            }
        } catch (error) {
            return null;
        }
    }

    const [modalPedidosClienteData, setModalPedidosClienteData] = useState(false);
    const [msgPedidosClienteData, setMsgPedidosClienteData] = useState('');

    const acaoPedidosClienteData = (status) =>{
        if(status){
            setModalPedidosClienteData(false);
        } else {
            setModalPedidosClienteData(false);
            setCliente(null);
            setClienteSelecionado('');
            if(localStorage.getItem('cliente_pedido')){
                localStorage.removeItem('cliente_pedido');
            }
        }
    }
    
    useEffect(() => {
        if (cliente !== null) {
            pedidosClienteData();
        }
    }, [cliente]);

    const carregarPedidoLancamento = async () => {
        let clienteId = '';
        if(id === null){
            clienteId = clienteLocal.id;
        } else {
            clienteId = id;
        }
       
        try {
            const response = await axios.post(urlDadosPedidoLancamento, {
                user_id: clienteId,
                id: id,
                token: tokenUsuario,
            })
            if (response.data.itens !== null) {
                setItensL(response.data.itens);
                setTotalSoma(response.data.total_soma);
            }
            if (response.data.pedidos !== null) {
                setPedidosL(response.data.pedidos[0]);
                setFormaPagamento(response.data.pedidos[0].forma_pagamento_id);
            }
            if (response.data.ingredientes !== null) {
                setIngredientesL(response.data.ingredientes);
            }
            if (response.data.itens !== null) {
                setItensL(response.data.itens);
            }
        } catch (error) {
            return null;
        }
    }

    useEffect(() => {
        if (id !== null) {
            carregarPedidoLancamento();
        }
    }, [id]);

    useEffect(() => {
        if (clienteLocal) {
            carregarPedidoLancamento();
        }
    }, []);

    const carregarClientes = async () => {
        try {
            const response = await axios.post(urlConsultaBuscaClientes, {
                token: tokenUsuario,
            })
            return response.data;
        } catch (error) {
            return null;
        }
    }

    const carregarFormasPagamento = async () => {
        try {
            const response = await axios.post(urlConsultaFormasPagamento, {
                token: tokenUsuario,
            })
            return response.data;
        } catch (error) {
            return null;
        }
    }

    const listarClientes = async () => {
        let clientesData = await carregarClientes();
        setClientes(clientesData);
    }

    const listarFormasPagamento = async () => {
        let pagamentoFormaData = await carregarFormasPagamento();
        setFormasPagamento(pagamentoFormaData);
    }

    useEffect(() => {
        listarClientes();
        listarFormasPagamento();
    }, []);

    useEffect(() => {
        const clienteLocal = localStorage.getItem('cliente_pedido');
        if (clienteLocal) {
            const parsedCliente = JSON.parse(clienteLocal);
            setClienteSelecionado(parsedCliente);
        }
    }, [clienteSelecionado]);

    const selecionarCliente = () => {
        if(cliente !== null){
            let clienteData = JSON.stringify(cliente);
            localStorage.setItem('cliente_pedido', clienteData);
            setClienteSelecionado(cliente);
        }
    }

    const buscarProdutos = async (produto) => {
        try {
            const response = await axios.post(urlConsultaBuscaProdutos, {
                nome: produto,
                token: tokenUsuario,
            })
            setProdutos(response.data);
        } catch (error) {
            return null;
        }
    }

    const apagarItemCarrinho = async (id) => {
        try {
            const response = await axios.post(urlRemoverItemCarrinho, {
                id: id,
                token: tokenUsuario,
            });
            if (response.data == 1) {
                carregarPedidoLancamento();
            }
        } catch (error) {
            return null;
        }
    }

    const enviarPedido = async () => {
        if (!formaPagamento) {
            return;
        }
        if (clienteLocal.id === null) {
            return;
        }
        try {
            const response = await axios.post(urlFinalizarPedido, {
                user_id: clienteSelecionado.id,
                forma_pagamento: formaPagamento,
                token: tokenUsuario,
            });
            if (response.data == 1) {
                setProduto('');
                localStorage.removeItem('cliente_pedido');
                setClienteSelecionado('');
                setFormaPagamento('');
                setItensL([]);
                setPedidosL([]);
                setIngredientesL([]);
                setTotalSoma('');
                setCliente(null);
                setSuccessAlert(true);
            } else {
                setErrorAlert(true);
            }
        } catch (error) {
            setErrorAlert(true);
        }
    }

    const limparCarrinho = async () => {
        try {
            const response = await axios.post(urlLimparCarrinho, {
                user_id: clienteSelecionado.id,
                token: tokenUsuario,
            });
            if (response) {
                setItensL([]);
                setPedidosL([]);
                setIngredientesL([]);
                setTotalSoma('');
            }
        } catch (error) {
            return null;
        }
    }

    return (
        <>
            <AlertCustom
                texto={"Pedido cadastrado com sucesso!"}
                color="success"
                isOpen={successAlert}
                setIsOpen={() => {
                    setSuccessAlert(false);
                }}
                tempo={2000}
            />
            <AlertCustom
                texto={"Não foi possível cadastrar o pedido!"}
                color="error"
                isOpen={erroAlert}
                setIsOpen={() => {
                    setErrorAlert(false);
                }}
                tempo={3000}
            />
            <ModalProduto
                isOpen={modalProduto}
                setIsOpen={fecharModalProduto}
                id={produtoSelecionado}
                userId={clienteSelecionado.id}
                carregarPedidoLancamento={carregarPedidoLancamento}
            />

            <ModalDialog
            isOpen={modalPedidosClienteData}
            setIsOpen={setModalPedidosClienteData}
            mensagem={msgPedidosClienteData}
            acaoBotao={acaoPedidosClienteData}
            />
            <div className='row'>
                <div className='col-sm-2'>
                    <MenuSide />
                </div>
                <div className='col-sm-10'>
                    <br />
                    <div className='card' style={{ marginRight: '1%' }}>
                        <div className='card-header'>
                            <div className='row'>
                                <div className='col-sm-3' style={{ fontWeight: 700, fontSize: '25px', }}>
                                    Adicionar pedido
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-sm-4'>
                                        {clienteSelecionado.nome !== undefined ? (
                                            <div className='card'>
                                                <span style={{ margin: 8 }}> {clienteSelecionado.nome} - {clienteSelecionado.nome_empresa}</span>
                                            </div>
                                        ) : (
                                            <Alert severity="error">Nenhum cliente selecionado!</Alert>
                                        )}   
            
                                    {id != null ? (
                                        <span></span>
                                    ) : (
                                        <>
                                            <br />
                                            <Autocomplete
                                                style={{ height: '40px' }}
                                                disablePortal
                                                id="combo-box-demo"
                                                options={clientes}
                                                getOptionLabel={(option, index) => `${option.id} - ${option.nome} - ${option.nome_empresa}`}                                                 
                                                value={cliente}
                                                onChange={(event, newValue) => {
                                                    event.preventDefault();
                                                    setCliente(newValue);
                                                }}
                                                filterOptions={(options, { inputValue }) => {
                                                    return options.filter(option => {
                                                      const nome = option.nome ? option.nome.toLowerCase() : '';
                                                      const nomeEmpresa = option.nome_empresa ? option.nome_empresa.toLowerCase() : '';
                                                      const lowerInputValue = inputValue.toLowerCase();
                                                  
                                                      return nome.includes(lowerInputValue) || nomeEmpresa.includes(lowerInputValue);
                                                    });
                                                  }}
                                                  
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        sx={{
                                                            '& .MuiInputBase-input': {

                                                            },
                                                            '& .MuiInputBase-root': {
                                                                height: "39px",
                                                                borderRadius: '0.5rem',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                height: "39px",
                                                                padding: '10px',
                                                            },
                                                            width: '100%',
                                                        }}
                                                    />
                                                )}
                                            />

                                            <br />
                                            <Button
                                                variant={'contained'}
                                                color={'error'}
                                                onClick={() => {
                                                    selecionarCliente();
                                                }}
                                                style={{
                                                    width: '100%',
                                                    height: 39,
                                                    borderRadius: '0.5rem',
                                                    marginTop: '4px',
                                                }}
                                            >
                                                Selecionar cliente
                                            </Button>
                                            <br />
                                        </>
                                    )}

                                    <br />
                                    <h6>Produtos</h6>
                                    <Input
                                        placeholder='Buscar produtos'
                                        onChange={(event) => {
                                            event.preventDefault();
                                            setProduto(event.target.value);
                                            buscarProdutos(event.target.value);
                                        }}
                                        value={produto}
                                        type="tel"
                                        style={{
                                            height: "39px",
                                            borderRadius: '0.5rem',
                                            width: '100%',
                                            padding: '10px'
                                        }} />
                                    {produto.length > 0 ? (
                                        <>
                                            {produtos.map((item, itemIndex) => (
                                                <div className='card' key={itemIndex} onClick={(event) => {
                                                    event.preventDefault();
                                                    setProdutoSelecionado(item.id);
                                                    if(clienteSelecionado){
                                                        setModalProduto(true);
                                                    }
                                                }}>
                                                    <ItemBuscaProduto nome={item.nome} id={produtoSelecionado} />
                                                </div>
                                            ))}
                                        </>
                                    ) : (
                                        <span></span>
                                    )}

                                </div>
                                <div className='col-sm-8'>
                                    <div className='card' style={{ padding: 20 }}>

                                        <Chip color={'success'} style={{ width: '29%' }} label={`Total R$ ${totalSoma}`} />
                                        <br />
                                        <FormControl>
                                            <FormLabel>Forma de pagamento</FormLabel>
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                                value={formaPagamento}
                                                onChange={(event) => {
                                                    setFormaPagamento(event.target.value);
                                                }}
                                            >
                                                {formasPagamento.map((item, index) => (
                                                    <FormControlLabel key={index} value={item.id} control={<Radio />} label={item.nome} />
                                                ))}
                                            </RadioGroup>
                                        </FormControl>
                                        <br />
                                        {formaPagamento !== null ? (
                                            <span></span>
                                        ) : (
                                            <Alert severity="error">Nenhuma forma de pagamento selecionada!</Alert>
                                        )}
                                        <br />
                                        <div className='row'>
                                            <div className='col-sm-6'>
                                                <Button
                                                    variant={'contained'}
                                                    color={'warning'}
                                                    onClick={() => {
                                                        limparCarrinho();
                                                    }}
                                                    style={{
                                                        width: '100%',
                                                        height: 39,
                                                        borderRadius: '0.5rem',
                                                        marginTop: '4px',
                                                    }}
                                                >
                                                    Limpar o carrinho
                                                </Button>
                                            </div>
                                            <div className='col-sm-6'>
                                                <Button
                                                    variant={'contained'}
                                                    color={'success'}
                                                    onClick={() => {
                                                        enviarPedido();
                                                    }}
                                                    style={{
                                                        width: '100%',
                                                        height: 39,
                                                        borderRadius: '0.5rem',
                                                        marginTop: '4px',
                                                    }}
                                                >
                                                    Enviar pedido
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <div className='row'>
                                        {itensL.map((item, itemIndex) => (
                                            <div className='col-sm-4' key={itemIndex}>
                                                <div className='card' style={{ marginBottom: 5 }} key={itemIndex}>
                                                    <div
                                                        style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 1, marginRight: 10 }}>
                                                        <Button
                                                            color={'error'}
                                                            style={{
                                                                borderRadius: 11,
                                                                width: '10%',
                                                                marginTop: 10
                                                            }}
                                                            variant={'contained'}
                                                            onClick={() => {
                                                                apagarItemCarrinho(item.id);
                                                            }}
                                                        >
                                                            <DeleteForeverIcon />
                                                        </Button>
                                                    </div>
                                                    <div style={{ padding: 10 }}>
                                                        <span>{item.nome_produto}</span>
                                                        <div>
                                                            Quantidade : {item.qnt} / R$ {item.valor_item} / R$ {item.total}
                                                        </div>
                                                        <br />
                                                        Adicionais
                                                        <br />
                                                        {ingredientesL.filter(ingrediente => ingrediente.item_id == item.id).map((ingrediente, ingredienteIndex) => (
                                                            <div key={ingredienteIndex}>
                                                                <span>{ingrediente.nome_ingrediente}</span>
                                                                <div>
                                                                    Quantidade : {ingrediente.qnt} / R$ {ingrediente.total} = R$ {ingrediente.total}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='card-footer'>
                            <div className='row'>
                                <div className='col-sm-3'></div>
                                <div className='col-sm-9 text-end'>

                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}