import React, { useState, useEffect } from 'react';
import { MenuSide } from '../../components/MenuSide';
import { TableDados } from './components/TableDados';
import { Autocomplete, TextField } from "@mui/material";
import InputCustom from '../../components/InputCustom';
import { tokenUsuario, urlConsultaEmpresas } from '../../utils/constants';
import axios from 'axios';

export default function Clientes() {

    const [filtro, setFiltro] = useState('');
    const [clientes, setClientes] = useState([]);
    const [cliente, setCliente] = useState(null);
    const [total, setTotal] = useState(0);

    const carregarClientes = async () => {
        try {
            const response = await axios.post(urlConsultaEmpresas, {
                token: tokenUsuario,
                filtrar: 1,
            })
            return response.data;
        } catch (error) {
            return null;
        }
    }

    const listarClientes = async () => {
        let clientesData = await carregarClientes();
        setClientes(clientesData);
    }

    useEffect(() => {
        listarClientes();
    }, []);

    return (
        <>
            <div className='row'>
                <div className='col-sm-2'>
                    <MenuSide />
                </div>
                <div className='col-sm-10' >
                    <br />
                    <div className='card' style={{ marginRight: '1%' }}>
                        <div className='card-header'>
                            <div className='row'>
                                <div className='col-sm-11' style={{ fontWeight: 700, fontSize: '25px', }}>
                                    Clientes Ativos em 2024 - Um total de {total} clientes
                                </div>
                                <div className='col-sm-1'></div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-sm-3'>
                                    <h6>Nome do item</h6>
                                    <InputCustom
                                        value={filtro}
                                        setValue={(event) => {
                                            event.preventDefault();
                                            setFiltro(event.target.value)
                                        }}
                                        type={'text'}
                                        placeHolder={'Nome do item'}

                                    />
                                </div>
                                <div className='col-sm-3'>
                                    <h6>Empresa</h6>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        options={clientes}
                                        getOptionLabel={(option) => `${option.nome}`}
                                        value={cliente}
                                        onChange={(event, newValue) => {
                                            setCliente(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                sx={{
                                                    '& .MuiInputBase-input': {

                                                    },
                                                    '& .MuiInputBase-root': {
                                                        height: "39px",
                                                        borderRadius: '0.5rem',
                                                    },
                                                    '& .MuiOutlinedInput-root': {
                                                        height: "39px",
                                                        padding: '10px',
                                                    },
                                                    width: '100%',
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <br />
                            <TableDados nome={filtro} empresa={cliente} totalValor={(value) => setTotal(value)} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
