import React, { useState } from "react";
import {
  Card,
  Modal,
  Box,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import { ModalDialog } from "../components/ModalDialog";
import { urlEnvioArquivo, tokenUsuario } from "../../../utils/constants";
import { AlertCustom } from "../../../components/AlertCustom";
import ButtonCustom from "../../../components/ButtonCustom";
import CarregandoCustom from "../../../components/CarregandoCustom";
import axios from "axios";

export const ModalEnviarArquivo = ({ isOpen, setIsOpen, numero }) => {
  const [arquivo, setArquivo] = useState("");
  const [arquivoNome, setArquivoNome] = useState("");
  const [categoria, setCategoria] = useState("");
  const [statusDialog, setStatusDialog] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [erroAlert, setErrorAlert] = useState(false);
  const [loading, setLoading] = useState(false);

  const altererarArquivo = (event) => {
    event.preventDefault();
    let typeFile = event.target.files[0].type;
    setArquivo(event.target.files[0]);
    setArquivoNome(event.target.files[0].name);
    if (typeFile) {
      if (typeFile.includes("image")) {
        setCategoria("image");
      } else if (typeFile.includes("audio")) {
        setCategoria("audio");
      } else {
        setCategoria("document");
      }
    } else {
      setCategoria("document");
    }
  };

  const enviarArquivo = async () => {
    const form = new FormData();
    form.append("file", arquivo);
    form.append("token", tokenUsuario);
    form.append("numero", numero);
    form.append("categoria", categoria);
    setLoading(true);
    try {
      const response = await axios.post(urlEnvioArquivo, form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setLoading(false);
      if (response.data.status == "enviado") {
        setSuccessAlert(true); // Ativar o alerta de sucesso
        setIsOpen(false);
      } else {
        setErrorAlert(true); // Ativar o alerta de erro
      }
    } catch (error) {
      setLoading(false);
      setErrorAlert(true); // Ativar o alerta de erro
    }
  };

  const fecharSucessAlert = () => {
    setSuccessAlert(false);
  };

  const fechaErrorAlert = () => {
    setErrorAlert(false);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div>
        <AlertCustom
          texto={"Arquivo enviado com sucesso"}
          color="success"
          isOpen={successAlert}
          setIsOpen={fecharSucessAlert}
          tempo={2000}
        />
        <AlertCustom
          texto={"Não foi possível enviar o arquivo!"}
          color="error"
          isOpen={erroAlert}
          setIsOpen={fechaErrorAlert}
          tempo={3000}
        />

        <Modal open={isOpen} onClose={setIsOpen}>
          <Box style={{ padding: "4% 37% 10% 37%" }}>
            <Card
              style={{
                maxHeight: "85vh",
                minHeight: "85vh",
                overflowY: "auto",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <IconButton variant="contained" onClick={handleClose}>
                  <CloseIcon
                    style={{
                      margin: "10px",
                    }}
                  />
                </IconButton>
              </div>
              <>
                <ModalDialog
                  isOpen={statusDialog}
                  setIsOpen={setStatusDialog}
                />
                <div style={{ margin: "20px" }}>
                  <h5 style={{ textAlign: "center", color: "#fff" }}>
                    Enviar arquivo
                  </h5>
                  <div className="row">&nbsp;</div>
                  <input className="form-control" onChange={altererarArquivo} type="file" />
                  <div className="row">&nbsp;</div>
                  {loading ? (
                    <CarregandoCustom />
                  ) : (
                    <>
                      {arquivoNome.length > 0 ? (
                        <ButtonCustom
                          onClick={() => {
                            enviarArquivo();
                          }}
                          color={"success"}
                          texto={" Enviar Arquivo"}
                          icon={SyncAltIcon}
                        />
                      ) : null}
                    </>
                  )}
                </div>
              </>
            </Card>
          </Box>
        </Modal>
      </div>
    </>
  );
};
