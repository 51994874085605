import React, { useState, useEffect } from 'react';
import { MenuSide } from '../../components/MenuSide';
import PedidosIcon from '@mui/icons-material/ConfirmationNumber';
import PedidosCarrinhoIcon from '@mui/icons-material/ShoppingCartOutlined';
import CardResumo from './CardResumo';
import CardAtalho from './CardAtalho';
import { tokenUsuario, urlResumoApp } from '../../utils/constants';
import axios from 'axios';

export default function Aplicativo() {

    const [pedidosAguardandoImpressao, setPedidosAguardandoImpressao] = useState(0);
    const [pedidosNoCarrinho, setPedidosNoCarrinho] = useState(0);
    const [clientesAguardandoAtendimento, setClientesAguardandoAtendimento] = useState(0);
    const [clientesUnicos, setClientesUnicos] = useState(0);
    const [enderecosNaoAtendidos, setEnderecosNaoAtendidos] = useState(0);

    const consultarInfo = async () => {
        try {
            const response = await axios.post(urlResumoApp, {
                token: tokenUsuario,
            })
            return response.data;
        } catch (error) {
            return [];
        }
    }

    const carregarInfo = async () => {
        const response = await consultarInfo();
        setPedidosAguardandoImpressao(response.pedidosAguardandoImpressao);
        setPedidosNoCarrinho(response.pedidosNoCarrinho);
        setClientesAguardandoAtendimento(response.clientesAguardandoAtendimento);
        setClientesUnicos(response.clientesUnicos);
        setEnderecosNaoAtendidos(response.enderecosNaoAtendidos);
    }

    useEffect(() => {
        carregarInfo();
        setInterval(() => {
            carregarInfo();
        }, 60000);
    }, []);

    return (
        <>
            <div className='row'>
                <div className='col-sm-2'>
                    <MenuSide />
                </div>
                <div className='col-sm-10'>

                    <br />
                    <div className='card'
                        style={{ marginRight: '1%', }}>
                        <div className='card-header'>
                            <div className='row'>
                                <div className='col-sm-10' style={{ fontWeight: 700, fontSize: '25px', }}>
                                    Monitoramento do Aplicativo
                                </div>
                                <div className='col-sm-2 text-end'>
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div>
                                <div className='row'>
                                    <div className='col-sm-3'>
                                        <CardResumo text={'Pedidos aguardando impressão'} color={'#D24245'} value={pedidosAguardandoImpressao} Icon={PedidosIcon} />
                                    </div>
                                    <div className='col-sm-3'>
                                        <CardResumo text={'Clientes fazendo pedido'} color={'#D24245'} value={pedidosNoCarrinho} Icon={PedidosCarrinhoIcon} />
                                    </div>
                                    <div className='col-sm-3'>
                                        <CardResumo text={'Clientes aguardando atendimento'} color={'#D24245'} value={clientesAguardandoAtendimento} Icon={PedidosCarrinhoIcon} />
                                    </div>
                                    <div className='col-sm-3'>
                                        <CardResumo text={'Clientes únicos nos últimos 180 dias'} color={'#D24245'} value={clientesUnicos} Icon={PedidosCarrinhoIcon} />
                                    </div>
                                    <div className='col-sm-12'>&nbsp;</div>
                                    <div className='col-sm-4'>
                                        <CardAtalho text={'Endereços não atendidos'} color={'#D24245'} value={enderecosNaoAtendidos} Icon={PedidosCarrinhoIcon} rota={'/enderecos_nao_atendidos'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}
