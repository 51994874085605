/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Modal, Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { ModalDialog } from "../components/ModalDialog";
import {
  urlMensagensAguardandoAtendimento,
  tokenUsuario,
} from "../../../utils/constants";
import axios from "axios";
import { TableAguardando } from "./TableAguardando";
export const ModalAguardando = ({
  isOpen,
  setIsOpen,
  alterarChat,
  countagemMensagens,
}) => {
  const [mensagensExibidas, setMensagensExibidas] = useState([]);
  const [countMsg, setCountMsg] = useState(0);
  const [statusDialog, setStatusDialog] = useState(false);
  const [nome, setNome] = useState("");
  const [numero, setNumero] = useState("");
  const [atendimento, setAtendimento] = useState("");

  const carregarMensagens = async () => {
    try {
      const response = await axios.post(urlMensagensAguardandoAtendimento, {
        token: tokenUsuario,
      });
      return response.data;
    } catch (error) {
      return null;
    }
  };

  const listarMensagens = async () => {
    let mensagens = await carregarMensagens();
    setMensagensExibidas(mensagens);
  };

  useEffect(() => {
    setCountMsg(countagemMensagens);
    if (countagemMensagens != countMsg) {
      listarMensagens();
    }
  }, [countagemMensagens, countMsg]);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div>
        <Modal open={isOpen} style={{ overflow: "scroll" }} onClose={setIsOpen}>
          <Box style={{ padding: "1" }}>
            <div className="card">
              <div className="card-header">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      flex: "95%",
                      justifyContent: "center",
                      fontWeight: 700,
                      fontSize: "25px",
                      marginTop: 10,
                    }}
                  >
                    <span>Clientes aguardando atendimento</span>
                  </div>
                  <div className="col-sm-6" style={{ flex: "5%" }}>
                    <IconButton onClick={handleClose}>
                      <CloseIcon
                        style={{
                          margin: "10px",
                        }}
                      />
                    </IconButton>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <ModalDialog
                  isOpen={statusDialog}
                  setIsOpen={setStatusDialog}
                  nome={nome}
                  numero={numero}
                  id={atendimento}
                  closeMainModal={handleClose}
                  alterarChat={alterarChat}
                />

                <TableAguardando
                  retAtendimento={(value) => setAtendimento(value)}
                  retNumero={(value) => setNumero(value)}
                  retStatusDialog={(value) => setStatusDialog(value)}
                  retNome={(value) => setNome(value)}
                  countagemMensagens={countagemMensagens}
                />
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
};
