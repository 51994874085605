/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";
import {
  urlEnvioMensagem,
  urlCarregarMensagens,
  urlCarregarChats,
  urlCountMensagensAguardandoAtendimento,
  urlCountMensagens,
  tokenUsuario,
  apiUrlArquivos,
} from "../../utils/constants";
import axios from "axios";
import { CircularProgress, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import AddCommentOutlinedIcon from "@mui/icons-material/AddCommentOutlined";
import CarregandoCustom from "../../components/CarregandoCustom";
import { MenuSide } from "../../components/MenuSide";

import { MensagemChat } from "./components/MensagemChat";
import { ItemChatLista } from "./components/ItemChatLista";
import { AlertAtendimentosEspera } from "./components/AlertAtendimentosEspera";
import { ModalAguardando } from "./components/ModalAguardando";
import { ModalDialogFinalizarAtendimento } from "./components/ModalDialogFinalizarAtendimento";
import { ModalTransferirAtendimento } from "./components/ModalTransferirAtendimento";
import { ModalEnviarArquivo } from "./components/ModalEnviarArquivo";
import { ModalListaContatos } from "./components/ModalListaContatos";
import { ModalContatoInfo } from "./components/ModalContatoInfo";
import InputCustom from "../../components/InputCustom";
import ButtonCustom from "../../components/ButtonCustom";

function primeiraLetraPrimeiroEUltimoNome(nomeCompleto) {
  if (nomeCompleto == undefined) {
    return;
  }
  const palavras = nomeCompleto.split(" ");

  if (palavras.length >= 2) {
    const primeiraLetraPrimeiroNome = palavras[0].charAt(0).toUpperCase();
    const ultimoNome = palavras[palavras.length - 1];
    const primeiraLetraUltimoNome = ultimoNome.charAt(0).toUpperCase();

    const corAleatoria =
      "#" + Math.floor(Math.random() * 16777215).toString(16);

    return [primeiraLetraPrimeiroNome + primeiraLetraUltimoNome, corAleatoria];
  } else {
    return null;
  }
}

export default function Chat() {
  const [contagemMensagens, setContagemMensagens] = useState(0);
  const [searchParams] = useSearchParams();
  const [mensagensExibidas, setMensagensExibidas] = useState([]);
  const [chats, setChats] = useState([]);
  const [mensagem, setMensagem] = useState([]);
  const [nomeContato, setNomeContato] = useState("");
  const [idAtendimentoContato, setIdAtendimentoContato] = useState([]);
  const [mensagemNumeroAtual, setMensagemNumeroAtual] = useState(
    searchParams.get("numero")
  );
  const [isLoading, setIsLoading] = useState(false);
  const [loadingChat, setloadingChat] = useState(true);
  const chatMensagensRef = useRef();
  const [countMsgAguardandoAtend, setCountMsgAguardandoAtend] = useState(0);
  const [
    statusModalAguardandoAtendimento,
    setStatusModalAguardandoAtendimento,
  ] = useState(false);
  const [loadEnvioMensagem, setLoadEnvioMensagem] = useState(false);

  const [modalFinalizarAtendimento, setModalFinalizarAtendimento] =
    useState(false);
  const [modalTransferirAtendimento, setModalTransferirAtendimento] =
    useState(false);
  const [modalListaContatos, setModalListaContatos] = useState(false);
  const [modalEnviarArquivo, setModalEnviarArquivo] = useState(false);
  const [modalContatoInfo, setModalContatoInfo] = useState(false);

  const [filtroNomeConversa, setFiltroNomeConversa] = useState("");
  const nomeAbreviado = primeiraLetraPrimeiroEUltimoNome(nomeContato) || "";

  const location = useLocation();
  const navigate = useNavigate();
  const searchParams2 = new URLSearchParams(location.search);

  const countAguardandoAtendimento = async () => {
    try {
      const response = await axios.post(
        urlCountMensagensAguardandoAtendimento,
        {
          token: tokenUsuario,
        }
      );
      return response.data[0].total;
    } catch (error) {
      return null;
    }
  };

  const countMensagens = async () => {
    try {
      const response = await axios.post(urlCountMensagens, {
        token: tokenUsuario,
      });
      return response.data.total;
    } catch (error) {
      return null;
    }
  };

  const carregarChats = async () => {
    try {
      const response = await axios.post(urlCarregarChats, {
        numero: mensagemNumeroAtual,
        token: tokenUsuario,
      });
      return response.data;
    } catch (error) {
      return null;
    }
  };

  const carregarMensagens = async (numero) => {
    try {
      const response = await axios.post(urlCarregarMensagens, {
        numero: numero,
        token: tokenUsuario,
      });
      if (response.data.mensagens) {
        return response.data.mensagens;
      } else {
        return [];
      }
    } catch (error) {
      return null;
    }
  };

  const listarContagemAguardando = async () => {
    const countMensagemAguardando = await countAguardandoAtendimento();
    setCountMsgAguardandoAtend(countMensagemAguardando);
  };

  const scrollToBottomDelayed = () => {
    setTimeout(() => {
      if (chatMensagensRef.current) {
        chatMensagensRef.current.scrollTo({
          top: chatMensagensRef.current.scrollHeight,
          behavior: "smooth",
        });
      }
    }, 100);
  };

  const listarMensagens = async (numero) => {
    const mensagensData = await carregarMensagens(
      numero || mensagemNumeroAtual
    );
    const chatsData = await carregarChats();
    if (mensagensData.length > 0) {
      setIdAtendimentoContato(mensagensData[0].atendimento_id);
    }
    if (chatsData) {
      setChats(chatsData);
      setloadingChat(false);
    }
    if (mensagensData) {
      setMensagensExibidas(mensagensData);
    }
    setTimeout(() => {
      scrollToBottomDelayed();
    }, 1200);
  };

  const verificarMensagensNovas = async () => {
    let contagem = await countMensagens();
    setContagemMensagens(contagem);
    if (contagem != contagemMensagens) {
      await listarMensagens();
      await listarContagemAguardando();
    }
  };

  const alterarChat = async (numero, nome, id) => {
    setIsLoading(true);
    setloadingChat(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1300);
    setMensagemNumeroAtual(numero);
    setNomeContato(nome);
    setIdAtendimentoContato(id);
    searchParams2.set("numero", numero);
    const novaURL = `${location.pathname}?${searchParams2.toString()}`;
    navigate(novaURL);
    listarMensagens(numero);
  };

  const enviarMensagem = () => {
    if (mensagemNumeroAtual) {
      setLoadEnvioMensagem(true);
      axios
        .post(urlEnvioMensagem, {
          mensagem: mensagem,
          tipo: 1,
          numero: mensagemNumeroAtual,
          token: tokenUsuario,
        })
        .then((response) => {
          if (response.data.status == "enviado") {
            setMensagem("");
            setLoadEnvioMensagem(false);
          } else {
            setLoadEnvioMensagem(false);
            alert("Não foi possível enviar sua mensagem");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const atualizarMensagemNumeroAtual = (novoNumero) => {
    setMensagemNumeroAtual(novoNumero);
  };

  const buscarAtendimentos = () => {
    setStatusModalAguardandoAtendimento(true);
  };

  const fecharModalFinalizarAtendimento = () => {
    setModalFinalizarAtendimento(false);
  };

  const fecharModalTransferirAtendimento = () => {
    setModalTransferirAtendimento(false);
  };

  const fecharModalListaContatos = () => {
    setModalListaContatos(false);
  };

  const fecharAtendimentoF = () => {
    setIdAtendimentoContato("");
    setNomeContato("");
    setMensagemNumeroAtual("");
    setMensagensExibidas([]);
    setChats([]);
  };

  const carregarNomeContato = (nome) => {
    setNomeContato(nome);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      verificarMensagensNovas();
    }, 1000);
    return () => clearInterval(intervalId);
  }, [mensagemNumeroAtual, contagemMensagens]);

  return (
    <>
      <div className="row">
        <ModalAguardando
          isOpen={statusModalAguardandoAtendimento}
          setIsOpen={setStatusModalAguardandoAtendimento}
          alterarChat={alterarChat}
          countagemMensagens={contagemMensagens}
        />

        <ModalListaContatos
          id={idAtendimentoContato}
          isOpen={modalListaContatos}
          setIsOpen={fecharModalListaContatos}
          atualizarMensagemNumeroAtual={atualizarMensagemNumeroAtual}
        />

        <ModalEnviarArquivo
          isOpen={modalEnviarArquivo}
          setIsOpen={setModalEnviarArquivo}
          numero={mensagemNumeroAtual}
        />

        <ModalDialogFinalizarAtendimento
          id={idAtendimentoContato}
          numero={mensagemNumeroAtual}
          nome={nomeContato}
          isOpen={modalFinalizarAtendimento}
          setIsOpen={fecharModalFinalizarAtendimento}
          fecharAtendimento={fecharAtendimentoF}
        />

        <ModalTransferirAtendimento
          id={idAtendimentoContato}
          isOpen={modalTransferirAtendimento}
          setIsOpen={fecharModalTransferirAtendimento}
          fecharAtendimento={fecharAtendimentoF}
        />

        <ModalContatoInfo
          isOpen={modalContatoInfo}
          setIsOpen={() => setModalContatoInfo(false)}
          numero={mensagemNumeroAtual}
          carregarNomeContato={carregarNomeContato}
        />

        <div className="col-sm-2">
          <MenuSide />
        </div>

        <div className="col-sm-3">
          <div
            onClick={() => {
              buscarAtendimentos();
            }}
          >
            <AlertAtendimentosEspera numero={countMsgAguardandoAtend} />
          </div>

          <div>
            <br />
            <ButtonCustom
              onClick={() => {
                setModalListaContatos(true);
              }}
              color={"success"}
              texto={"NOVO ATENDIMENTO"}
              icon={AddCommentOutlinedIcon}
            />
            <br />
            <br />
            <InputCustom
              value={filtroNomeConversa}
              setValue={(event) => {
                event.preventDefault();
                setFiltroNomeConversa(event.target.value);
              }}
              onkeydown={(e) => {
                if (e.key === "Enter") {
                  if (mensagem.length > 0) {
                    enviarMensagem();
                  }
                }
              }}
              type={"text"}
              placeHolder="Buscar uma conversa"
            />
            <br />
          </div>
          <div
            ref={chatMensagensRef}
            style={{
              maxHeight: "95vh",
              minHeight: "95vh",
              overflowY: "auto",
              marginTop: 5,
            }}
          >
            {loadingChat ? (
              <CarregandoCustom />
            ) : (
              <>
                {Array.isArray(chats) &&
                  chats
                    .filter((item) =>
                      item.nome_contato
                        ?.toLowerCase()
                        .includes(filtroNomeConversa.toLowerCase())
                    )
                    .map((item, index) => (
                      <React.Fragment key={index}>
                        <div
                          onClick={() => {
                            alterarChat(
                              item.numero,
                              item.nome_contato,
                              item.id
                            );
                          }}
                        >
                          <ItemChatLista
                            id={item.id}
                            numero={item.numero}
                            nome={item.nome_contato}
                            foto={item.foto}
                            mensagens={item.mensagens_status_count}
                          />
                        </div>
                      </React.Fragment>
                    ))}
              </>
            )}
          </div>
        </div>
        <div className="col-sm-7">
          <div className="card">
            <div style={{ borderRadius: "0.5rem", margin: "10px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingLeft: "20px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                }}
              >
                <div style={{ flex: "4%" }}>
                  <div
                    style={{
                      height: 35,
                      width: 35,
                      borderRadius: "0.5rem",
                      background: "#D24245",
                      color: "#ffffff",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {nomeAbreviado[0]}
                  </div>
                </div>
                <div style={{ flex: "1%" }}></div>
                <div style={{ flex: "76%" }}>
                  <IconButton
                    onClick={() => {
                      setModalContatoInfo(true);
                    }}
                    style={{
                      backgroundColor: "transparent",
                      textTransform: "capitalize",
                      fontSize: 16,
                    }}
                  >
                    {nomeContato}
                  </IconButton>
                </div>
                <div style={{ flex: "20%" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div>
                      <IconButton
                        onClick={() => {
                          setModalTransferirAtendimento(true);
                        }}
                      >
                        <SwapHorizIcon />
                      </IconButton>
                    </div>
                    <div>
                      <IconButton
                        onClick={() => {
                          setModalFinalizarAtendimento(true);
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="card"
              ref={chatMensagensRef}
              style={{
                margin: "10px",
                maxHeight: "74vh",
                minHeight: "74vh",
                overflowY: "auto",
                padding: "10px",
              }}
            >
              {isLoading ? (
                <CarregandoCustom />
              ) : (
                <div>
                  {mensagensExibidas.map((item, index) => (
                    <div className="row" key={index}>
                      <div
                        className={`col-sm-6 ${
                          item.tipo == 1 ? "order-last" : ""
                        }`}
                      >
                        <MensagemChat
                          mensagem={item.mensagem}
                          tipo={item.tipo}
                          data={item.data}
                          categoria={item.categoria}
                          media={item.media}
                          arquivo={apiUrlArquivos + item.url_arquivo}
                        />
                      </div>
                      <div className="col-sm-6"></div>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div
              style={{
                borderRadius: "0.5rem",
                paddingTop: "6px",
                paddingBottom: "4px",
                margin: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "flex-row",
                  justifyContent: "center",
                }}
              >
                <div style={{ flex: "5%" }}>
                  <IconButton
                    onClick={() => {
                      setModalEnviarArquivo(true);
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </div>
                <div style={{ flex: "1%" }}></div>
                <div style={{ flex: "86%" }}>
                  <InputCustom
                    value={mensagem}
                    setValue={(event) => {
                      event.preventDefault();
                      setMensagem(event.target.value);
                    }}
                    onkeydown={(e) => {
                      if (e.key === "Enter") {
                        if (mensagem.length > 0) {
                          enviarMensagem();
                        }
                      }
                    }}
                    type={"text"}
                    placeHolder="Digite uma mensagem"
                  />
                </div>
                <div style={{ flex: "1%" }}></div>
                <div style={{ flex: "4%", padding: "10x" }}>
                  <IconButton
                    disabled={mensagem.length === 0}
                    onClick={() => {
                      enviarMensagem();
                    }}
                  >
                    {loadEnvioMensagem ? (
                      <CircularProgress color="primary" />
                    ) : (
                      <SendIcon />
                    )}
                  </IconButton>
                </div>
                <div style={{ flex: "1%" }}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
