import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { MenuSide } from '../../components/MenuSide';
import { Select, MenuItem, FormControl, TextField } from "@mui/material";
import { urlAdicionarIngredientes, urlConsultaCategorias, tokenUsuario } from "../../utils/constants";
import ButtonCustom from '../../components/ButtonCustom';
import SelectCustom from '../../components/SelectCustom';

export default function AdicionarIngredientes() {

    const navigate = useNavigate();
    const [nome, setNome] = useState('');
    const [valor, setValor] = useState('');
    const [limite, setLimite] = useState('');
    const [status, setStatus] = useState(1);
    const [categoria, setCategoria] = useState('');
    const [categorias, setCategorias] = useState([]);

    const adicionarInformacoes = async () => {
        try {
            const response = await axios.post(urlAdicionarIngredientes, {
                nome: nome,
                valor: valor,
                limite: limite,
                status: status,
                categoria: categoria,
                token: tokenUsuario,
            });
            if (response.data == 1) {
                navigate({
                    pathname: "/ingredientes",
                });
            }
        } catch (error) {
            return null;
        }
    };

    const consultarCategorias = async () => {
        try {
            const response = await axios.post(urlConsultaCategorias, {
                token: tokenUsuario,
            });
            return response.data;
        } catch (error) {
            return null;
        }
    };

    useEffect(() => {
        const run = async () => {
            let categoriasData = await consultarCategorias();
            setCategorias(categoriasData);
        }
        run();
    }, []);

    return (
        <>
            <div className='row'>
                <div className='col-sm-2'>
                    <MenuSide />
                </div>
                <div className='col-sm-10'>
                    <br />
                    <div className='card' style={{ marginRight: '1%' }}>
                        <div className='card-header'>
                            <div className='row'>
                                <div className='col-sm-3' style={{ fontWeight: 700, fontSize: '25px', }}>
                                    Adicionar ingrediente
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-sm-4'>
                                    <h6>Nome</h6>
                                    <FormControl fullWidth>
                                        <TextField
                                            placeholder='Nome'
                                            onChange={(event) => {
                                                event.preventDefault();
                                                setNome(event.target.value)
                                            }}
                                            value={nome}
                                            type="text"
                                            InputProps={{
                                                sx: {
                                                    height: "39px",
                                                    borderRadius: '0.5rem',
                                                    width: '100%',
                                                    padding: '10px',
                                                }
                                            }} variant="outlined" />
                                    </FormControl>
                                </div>
                                <div className='col-sm-3'>
                                    <h6>Valor</h6>
                                    <FormControl fullWidth>
                                        <TextField
                                            placeholder='Valor'
                                            onChange={(event) => {
                                                event.preventDefault();
                                                setValor(event.target.value)
                                            }}
                                            value={valor}
                                            type="tel"
                                            InputProps={{
                                                sx: {
                                                    height: "39px",
                                                    borderRadius: '0.5rem',
                                                    width: '100%',
                                                    padding: '10px',
                                                }
                                            }} variant="outlined" />
                                    </FormControl>
                                </div>

                                <div className='col-sm-3'>
                                    <h6>Limite de disponibilidade</h6>
                                    <FormControl fullWidth>
                                        <TextField
                                            placeholder='Limite de disponibilidade'
                                            onChange={(event) => {
                                                event.preventDefault();
                                                setLimite(event.target.value)
                                            }}
                                            value={limite}
                                            type="number"
                                            InputProps={{
                                                sx: {
                                                    height: "39px",
                                                    borderRadius: '0.5rem',
                                                    width: '100%',
                                                    padding: '10px',
                                                }
                                            }} variant="outlined" />
                                    </FormControl>
                                </div>
                                <div className='col-sm-3'>
                                    <br />
                                    <h6>Categoria</h6>
                                    <SelectCustom
                                        value={categoria}
                                        setValue={(event) => {
                                            event.preventDefault();
                                            setCategoria(event.target.value);
                                        }}
                                        itens={categorias}
                                        valueItem={"id"}
                                        nameItem={"nome"}
                                    />
                                </div>
                                <div className='col-sm-3'>
                                    <br />
                                    <h6>Disponibilidade</h6>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={status}
                                        style={{
                                            borderRadius: '0.5rem',
                                            height: "39px",
                                            marginLeft: "3px",
                                            marginRight: "3px",
                                            width: '100%'
                                        }}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    borderRadius: '0.5rem',
                                                },
                                            }
                                        }}
                                        onChange={(event) => {
                                            event.preventDefault();
                                            setStatus(event.target.value);
                                        }}
                                    >
                                        <MenuItem value={1}>Disponível</MenuItem>
                                        <MenuItem value={2}>Indisponível</MenuItem>
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <div className='card-footer'>
                            <div className='row'>
                                <div className='col-sm-3'></div>
                                <div className='col-sm-7'></div>
                                <div className='col-sm-2 text-end'>
                                    <ButtonCustom
                                        onClick={() => {
                                            adicionarInformacoes();
                                        }}
                                        color={"error"}
                                        texto={"Confirmar"}
                                        icon={null}
                                    />
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}
