/* eslint-disable  no-unused-vars */
import React, { useState, useEffect } from "react";
import { Card, Modal, Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ModalDialog } from "../components/ModalDialog";
import { urlInfoGeralContato, tokenUsuario } from "../../../utils/constants";
import axios from "axios";
import { AlertCustom } from "../../../components/AlertCustom";
import Pedidos from "../../pedidos/index";

export const ModalContatoInfo = ({ isOpen, setIsOpen, numero, carregarNomeContato }) => {
  const [statusDialog, setStatusDialog] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [erroAlert, setErrorAlert] = useState(false);
  const [info, setInfo] = useState([]);
  const [clienteId, setClienteId] = useState(null);

  const carregarContato = async () => {
    try {
      const response = await axios.post(urlInfoGeralContato, {
        token: tokenUsuario,
        numero: numero,
      });
      return response.data;
    } catch (error) {
      return [];
    }
  };

  const fecharSucessAlert = () => {
    setSuccessAlert(false);
  };

  const fechaErrorAlert = () => {
    setErrorAlert(false);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const run = async () => {
      const response = await carregarContato();
      setInfo(response.informacoes);
      setClienteId(response.informacoes.id);
      carregarNomeContato(response.informacoes.nome);
    };
    run();
  }, [numero]);

  const statusTextArray = [
    { id: "1", nome: "Pendente" },
    { id: "2", nome: "Em curso" },
    { id: "4", nome: "Finalizado" },
    { id: "5", nome: "Cancelado" },
    { id: "6", nome: "Reembolsado" },
    { id: "7", nome: "Em lançamento" },
  ];

  return (
    <>
      <div>
        <AlertCustom
          texto={"Atendimento iniciado com sucesso"}
          color="success"
          isOpen={successAlert}
          setIsOpen={fecharSucessAlert}
          tempo={2000}
        />
        <AlertCustom
          texto={" Não foi possível iniciar o atendimento!"}
          color="error"
          isOpen={erroAlert}
          setIsOpen={fechaErrorAlert}
          tempo={3000}
        />

        <Modal open={isOpen} onClose={setIsOpen}>
          <Box style={{ padding: "2% 4% 10% 2%" }}>
            <Card
              style={{
                maxHeight: "85vh",
                minHeight: "85vh",
                overflowY: "auto",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <IconButton onClick={handleClose}>
                  <CloseIcon
                    style={{
                      margin: "10px",
                    }}
                  />
                </IconButton>
              </div>
              <>
                <ModalDialog
                  isOpen={statusDialog}
                  setIsOpen={setStatusDialog}
                />
                <div style={{ marginLeft: '10px', marginRight: '10px' }}>
                  <>
                    <Pedidos clienteId={clienteId} nomeCliente={` : ${info.nome} (${info.numero})`} />
                  </>
                </div>
              </>
            </Card>
          </Box>
        </Modal>
      </div>
    </>
  );
};
