import React from "react";
import { Button } from "@mui/material";

export const ItemMenuSide = ({ funcao, nome, Icon, caminho, rota }) => {
  let color = "#000000";
  const theme = localStorage.getItem("theme");
  if (theme === "dark") {
    color = "#ffffff";
  }
  return (
    <div
      style={{
        margin: "4px 12px",
      }}
    >
      <Button
        onClick={() => {
          funcao();
        }}
        color="error"
        style={{
          margin: 0,
          fontWeight: 400,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          transition: "transform 750ms",
          willChange: "transform",
          textTransform: "capitalize",
          height: "40px",
          borderRadius: "0.5rem",
          width: "100%",
          fontSize: "14px",
          backgroundColor: caminho === rota ? "#D24245" : "transparent",
          color: caminho === rota ? "#ffffff" : color,
        }}
      >
        &nbsp;&nbsp;&nbsp;
        {Icon && <Icon style={{ fontSize: "16px" }} />}
        <span style={{ marginLeft: "10px" }}>{nome}</span>
      </Button>
    </div>
  );
};
