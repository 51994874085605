import React, { useState, useEffect } from 'react';
import axios from "axios";
import { MenuSide } from '../../components/MenuSide';
import { MensagemChat } from '../chat/components/MensagemChat';
import { urlInformacoesAtendimento, tokenUsuario, apiUrlArquivos } from "../../utils/constants";

export default function AlterarContato() {

    const [atendimento, setAtendimento] = useState([]);
    const [mensagens, setMensagens] = useState([]);
    const [dataAtendimento, setDataAtendimento] = useState('');

    const url = window.location.href;
    const urlBase = new URL(url);

    const informacoesAtendimentos = async () => {
        try {
            const response = await axios.post(urlInformacoesAtendimento, {
                id: urlBase.searchParams.get('id'),
                token: tokenUsuario,
            });
            return response.data;
        } catch (error) {
            return null;
        }
    };

    useEffect(() => {
        const run = async () => {
            const info = await informacoesAtendimentos();
            setAtendimento(info.atendimento);
            setMensagens(info.mensagens);
            setDataAtendimento(info.data_atendimento);
        }
        run();
    }, []);

    return (
        <>
            <div className='row'>
                <div className='col-sm-2'>
                    <MenuSide />
                </div>
                <div className='col-sm-10'>
                    <div className='card'
                        style={{ margin: '30px', }}>
                        <div className='card-header'>
                            <div className='row'>
                                <div className='col-sm-12' style={{ fontWeight: 700, fontSize: '25px', }}>
                                    Informações do atendimento: {atendimento.nome} em {dataAtendimento}
                                </div>
                            </div>
                        </div>
                        <div className='card-body' style={{
                            maxHeight: '85vh',
                            minHeight: '85vh',
                            overflowY: 'auto',
                        }}>
                            {mensagens.map((item, index) => (
                                <div className="row" key={index}>
                                    <div className={`col-sm-6 ${item.tipo == 1 ? 'order-last' : ''}`}>
                                        <MensagemChat mensagem={item.mensagem} tipo={item.tipo} data={item.data} categoria={item.categoria} media={item.media} arquivo={apiUrlArquivos + item.url_arquivo} />
                                    </div>
                                    <div className="col-sm-6"></div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}
