import React, { useState, useEffect } from 'react';
import axios from "axios";
import { MenuSide } from '../../components/MenuSide';
import { urlListarDiasNaoAtendidos, tokenUsuario } from "../../utils/constants";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import ptBrLocale from '@fullcalendar/core/locales/pt-br';
import { ModalData } from './components/ModalData';

export default function DiasNaoAtendidos() {

    const [eventos, setEventos] = useState([]);
    const [dataSelecionada, setDataSelecionada] = useState('');
    const [modalData, setModalData] = useState(false);

    const fecharModalData = () => {
        setModalData(false);
    }

    const carregarDiasNaoAtendidos = async () => {
        try {
            const response = await axios.post(urlListarDiasNaoAtendidos, {
                token: tokenUsuario,
            });
            return response.data;
        } catch (error) {
            return null;
        }
    };

    const listarDiasNaoAtendidos = async () => {
        const eventsData = await carregarDiasNaoAtendidos();
        setEventos(eventsData);
    }

    useEffect(() => {
        listarDiasNaoAtendidos();
    }, [eventos]);

    const handleDateClick = (event) => {
        setDataSelecionada(event.dateStr);
        setModalData(true);
    }

    return (
        <>
            <div className='row'>
                <div className='col-sm-2'>
                    <MenuSide />
                </div>
                <div className='col-sm-10'>

                    <ModalData
                        isOpen={modalData}
                        setIsOpen={fecharModalData}
                        data={dataSelecionada}
                    />

                    <br /> <div className='card'
                        style={{ marginRight: '1%',  }}>
                        <div className='card-header'>
                            <div className='row'>
                                <div className='col-sm-3' style={{ fontWeight: 700, fontSize: '25px', }}>
                                    Dias não atendidos
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <FullCalendar
                                initialView="dayGridMonth"
                                weekends={true}
                                locales={[ptBrLocale]}
                                locale="pt-br"
                                events={eventos}
                                dateClick={handleDateClick}
                                plugins={[
                                    dayGridPlugin,
                                    interactionPlugin,
                                ]}
                                eventClick={event => {
                                    console.log(event.event._def.publicId);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
