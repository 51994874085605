/* eslint-disable react/no-unknown-property */
import React, { useState, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import axios from "axios";
import { Button, CircularProgress, Paper } from '@mui/material';
import {
    Dialog,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import { tokenUsuario, urlRelatorioPedidos } from '../../utils/constants';
import { MenuSide } from '../../components/MenuSide';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ArticleIcon from '@mui/icons-material/Article';
import FilterListIcon from '@mui/icons-material/FilterList';
import html2pdf from 'html2pdf.js';
import InputCustom from '../../components/InputCustom';

export function converterDataFormato(dataOriginal) {
    //@ts-ignore
    const partes = dataOriginal.split(" ");
    const dataPartes = partes[0].split("-");
    const horaPartes = partes[1].split(":");

    const dia = dataPartes[2];
    const mes = dataPartes[1];
    const ano = dataPartes[0];

    const hora = horaPartes[0];
    const minutos = horaPartes[1];

    const dataFormatada = `${dia}/${mes}/${ano} ${hora}:${minutos}`;

    return dataFormatada;
}

export default function RelatorioPedidos() {

    const [progressOpen, setProgressOpen] = useState(false);

    const filtrarInformacoes = async () => {
        setLoading(true);
        try {
            const response = await axios.post(urlRelatorioPedidos, {
                token: tokenUsuario,
                dataInicial: dataInicial,
                dataFinal: dataFinal
            })
            setPedidos(response.data.pedidos);
            setTotalItens(response.data.total_itens);
            setTotalSoma(response.data.total_soma);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    const exportarParaExcel = (event) => {
        event.preventDefault();
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';

        const ws = XLSX.utils.json_to_sheet(pedidos.map(pedido => ({
            'Nº': pedido.pedido_id,
            'Cliente': pedido.nome_usuario,
            'Empresa': pedido.nome_empresa,
            'Forma de pagamento': pedido.nome_forma_pagamento,
            'Período': pedido.data,
            'Valor': pedido.valor
        })));

        const wb = { Sheets: { 'pedidos': ws }, SheetNames: ['pedidos'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, 'relatorio_pedidos' + fileExtension);
    }

    function addZero(num) {
        return num < 10 ? `0${num}` : num;
    }

    const exportarParaPDF = async () => {
        const element = componentRef.current;
        let date = new Date();
        const dia = addZero(date.getDate());
        const mes = addZero(date.getMonth() + 1);
        const ano = date.getFullYear();
        const hora = addZero(date.getHours());
        const minuto = addZero(date.getMinutes());
        const segundo = addZero(date.getSeconds());

        const dataFormatada = `${dia}/${mes}/${ano} ${hora}:${minuto}:${segundo}`;

        const pdfOptions = {
            margin: 10,
            filename: `relatorio_pedidos_${dataInicial}_${dataFinal}_gerado_${dataFormatada}.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 1 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        };
        setProgressOpen(true);
        await html2pdf().from(element).set(pdfOptions).save();
        setProgressOpen(false);
    };


    const componentRef = useRef();
    const [loading, setLoading] = useState(0);
    const [dataInicial, setDataInicial] = useState('');
    const [dataFinal, setDataFinal] = useState('');
    const [pedidos, setPedidos] = useState([]);
    const [totalItens, setTotalItens] = useState(0);
    const [totalSoma, setTotalSoma] = useState(0);

    return (
        <>
            <Dialog open={progressOpen} onClose={() => setProgressOpen(false)}
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <DialogTitle>Gerando PDF...</DialogTitle>
                <DialogContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress />
                </DialogContent>
            </Dialog>

            <div className='row'>
                <div className='col-sm-2'>
                    <MenuSide />
                </div>
                <div className='col-sm-10'>
                    <br />
                    <div className='card' style={{ marginRight: '1%' }}>
                        <div className='card-header'>
                            <div className='row'>
                                <div className='col-sm-3' style={{ fontWeight: 700, fontSize: '25px', }}>
                                    Relatório de Pedidos
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-sm-2'>
                                    <h6>Data inicial</h6>
                                    <InputCustom
                                        value={dataInicial}
                                        setValue={(event) => {
                                            event.preventDefault();
                                            setDataInicial(event.target.value)
                                        }}
                                        type={'date'}
                                    />
                                </div>
                                <div className='col-sm-2'>
                                    <h6>Data final</h6>
                                    <InputCustom
                                        value={dataFinal}
                                        setValue={(event) => {
                                            event.preventDefault();
                                            setDataFinal(event.target.value)
                                        }}
                                        type={'date'}
                                    />
                                </div>
                                <div className='col-sm-2'>
                                    <br />
                                    <Button
                                        variant='contained'
                                        onClick={() => {
                                            filtrarInformacoes();
                                        }}
                                        style={{
                                            width: '100%',
                                            height: 39,
                                            borderRadius: '0.5rem',
                                            marginTop: '4px',
                                        }}
                                    >
                                        BUSCAR  &nbsp;&nbsp; <FilterListIcon style={{ fontSize: 16 }} />
                                    </Button>
                                </div>
                                <div className='col-sm-1'>
                                    <br />
                                    <Button
                                        variant='contained'
                                        color={'error'}
                                        onClick={() => {
                                            setProgressOpen(true);
                                            exportarParaPDF();
                                        }}
                                        style={{
                                            width: '100%',
                                            height: 39,
                                            borderRadius: '0.5rem',
                                            marginTop: '4px',
                                        }}
                                    >
                                        <PictureAsPdfIcon style={{ fontSize: 16 }} />
                                    </Button>
                                </div>
                                <div className='col-sm-1'>
                                    <br />
                                    <a
                                        id="downloadLink"
                                        onClick={exportarParaExcel}
                                    >
                                        <Button
                                            variant='contained'
                                            color={'success'}

                                            style={{
                                                width: '100%',
                                                height: 39,
                                                borderRadius: '0.5rem',
                                                marginTop: '4px',
                                            }}
                                        >
                                            <ArticleIcon style={{ fontSize: 16 }} />
                                        </Button>
                                    </a>
                                </div>
                                <div className='col-sm-1'>
                                    <br />
                                    <ReactToPrint
                                        trigger={() => (
                                            <Button
                                                variant='contained'
                                                color='info'
                                                style={{
                                                    width: '100%',
                                                    height: 39,
                                                    borderRadius: '0.5rem',
                                                    marginTop: '4px',
                                                }}
                                            >
                                                <span><LocalPrintshopIcon style={{ fontSize: 16 }} /></span>
                                            </Button>
                                        )}
                                        content={() => componentRef.current}
                                    />
                                </div>
                            </div>

                            <br />

                            <div id='imprimir' ref={componentRef}>

                                {loading ? (
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', marginTop: '20%' }}>
                                        <CircularProgress color="primary" />
                                    </div>
                                ) : (
                                    <>
                                        <div className="containerFaixaRelatorio">

                                            <div className="itemImagemFaixaRelatorio">
                                                <img src="https://sistema-marmita-express.gerenciadores.com.br/logo192.png" className="ImagemFaixaRelatorio" alt="" />
                                            </div>

                                            <div className="itemFaixaRelatorio">
                                                &nbsp;&nbsp;MARMITA EXPRESS<br />
                                                <h6 className="descricaoItemRelatorio" style={{ marginTop: 10 }}>&nbsp;&nbsp;&nbsp;&nbsp;Relatório de pedidos</h6>
                                            </div>

                                        </div>
                                        <div component={Paper}>
                                            <div sx={{ minWidth: 650 }} aria-label="simple table">
                                                <table className='table table'>
                                                    <thead>
                                                        <tr>
                                                            <th>Nº</th>
                                                            <th>Cliente</th>
                                                            <th>Empresa</th>
                                                            <th>Forma de pagamento</th>
                                                            <th>Período</th>
                                                            <th>Valor</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {pedidos.map((item, index) => (
                                                            <tr className='avoidPageBreak' key={index}>
                                                                <td>{item.pedido_id}</td>
                                                                <td>{item.nome_usuario}</td>
                                                                <td>{item.nome_empresa}</td>
                                                                <td>{item.nome_forma_pagamento}</td>
                                                                <td>{converterDataFormato(item.data)}</td>
                                                                <td>R$ {item.valor}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className='text-end'>
                                            <span>Total de marmitas:{totalItens}</span>
                                            &nbsp;&nbsp;&nbsp;&nbsp; / &nbsp;&nbsp;&nbsp;&nbsp;
                                            <span>R$ {totalSoma}</span>
                                        </div>
                                    </>
                                )}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
