import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import CloseIcon from "@mui/icons-material/Close";

export const ModalRelatorios = ({ modalRelatorio, closeModalRelatorio }) => {
  const handleNavigate = (path) => {
    closeModalRelatorio();
    navigate(path);
  };

  const listarRelatorios = [
    {
      text: "Pedidos",
      icon: <AssessmentOutlinedIcon />,
      path: "/relatorios_pedidos",
    },
    {
      text: "Pedidos por forma de pagamento",
      icon: <AssessmentOutlinedIcon />,
      path: "/relatorios_pedidos_forma_pagamento",
    },
    {
      text: "Marmitas por Empresa",
      icon: <AssessmentOutlinedIcon />,
      path: "/relatorios_marmitas_por_empresa",
    },
    {
      text: "Pedidos por Cliente",
      icon: <AssessmentOutlinedIcon />,
      path: "/relatorios_pedidos_clientes",
    },
    {
      text: "Pedidos por Empresa",
      icon: <AssessmentOutlinedIcon />,
      path: "/relatorios_pedidos_empresas",
    },
  ];

  const navigate = useNavigate();

  return (
    <>
      <Drawer
        anchor="left"
        open={modalRelatorio}
        onClose={closeModalRelatorio}
        className="my-custom-class"
      >
        <List>
          {listarRelatorios.map((item, index) => (
            <ListItem
              button
              key={index}
              onClick={() => handleNavigate(item.path)}
            >
              <AssessmentOutlinedIcon style={{ fontSize: "16px" }} />
              &nbsp; &nbsp;
              <ListItemText
                primaryTypographyProps={{ fontSize: "14px" }}
                primary={item.text}
              />
            </ListItem>
          ))}
          <ListItem button key="Fechar" onClick={closeModalRelatorio}>
            <CloseIcon style={{ fontSize: "16px" }} />
            &nbsp; &nbsp;
            <ListItemText
              primaryTypographyProps={{ fontSize: "14px" }}
              primary="Fechar"
            />
          </ListItem>
        </List>
      </Drawer>
    </>
  );
};
