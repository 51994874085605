import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useNavigate, createSearchParams } from "react-router-dom";
import { MenuSide } from '../../components/MenuSide';
import { Select, MenuItem, TextField, FormControl } from "@mui/material";
import { urlAdicionarProdutos, urlConsultaCategorias, tokenUsuario } from "../../utils/constants";
import ButtonCustom from '../../components/ButtonCustom';
import InputCustom from '../../components/InputCustom';
import SelectCustom from '../../components/SelectCustom';

export default function AdicionarProduto() {

    const navigate = useNavigate();
    const [nome, setNome] = useState('');
    const [valor, setValor] = useState('');
    const [carneLimite, setCarneLimite] = useState('');
    const [tipo, setTipo] = useState('');
    const [categoria, setCategoria] = useState('');
    const [tamanho, setTamanho] = useState('');
    const [imagem, setImagem] = useState('');
    const [categorias, setCategorias] = useState([]);

    const adicionarInformacoes = async () => {

        const form = new FormData();
        form.append("imagem", imagem);
        form.append("nome", nome);
        form.append("valor", valor);
        form.append("carne_limite", carneLimite);
        form.append("tipo", tipo);
        form.append("categoria", categoria);
        form.append("tamanho", tamanho);
        form.append("token", tokenUsuario);

        try {
            const response = await axios.post(urlAdicionarProdutos, form, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            if (response.data.status == 1) {
                navigate({
                    pathname: "/produtos/alterar",
                    search: createSearchParams({
                        id: response.data.id,
                    }).toString(),
                });
            }
        } catch (error) {
            return null;
        }
    };

    const consultarCategorias = async () => {
        try {
            const response = await axios.post(urlConsultaCategorias, {
                token: tokenUsuario,
            });
            return response.data;
        } catch (error) {
            return null;
        }
    };

    useEffect(() => {
        const run = async () => {
            let categoriasData = await consultarCategorias();
            setCategorias(categoriasData);
        }
        run();
    }, []);

    const tamanhosProdutos = [
        {
            id: 'M',
            nome: 'Mini'
        },
        {
            id: 'G',
            nome: 'Grande'
        },
        {
            id: 'E',
            nome: 'Executiva'
        },
        {
            id: 'N',
            nome: 'Não informado'
        }
    ];

    const altererarArquivo = (event) => {
        event.preventDefault();
        setImagem(event.target.files[0]);
    };

    return (
        <>
            <div className='row'>
                <div className='col-sm-2'>
                    <MenuSide />
                </div>
                <div className='col-sm-10'>
                    <br />
                    <div className='card' style={{ marginRight: '1%' }}>
                        <div className='card-header'>
                            <div className='row'>
                                <div className='col-sm-3' style={{ fontWeight: 700, fontSize: '25px', }}>
                                    Adicionar produto
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-sm-3'>
                                    <h6>Nome</h6>
                                    <FormControl fullWidth>
                                        <TextField
                                            placeholder='Nome'
                                            onChange={(event) => {
                                                event.preventDefault();
                                                setNome(event.target.value)
                                            }}
                                            value={nome}
                                            type="text"
                                            InputProps={{
                                                width: '100%',
                                                sx: {
                                                    height: "39px",
                                                    borderRadius: '0.5rem',
                                                    width: '100%',
                                                    padding: '10px',
                                                }
                                            }} variant="outlined" />
                                    </FormControl>
                                </div>
                                <div className='col-sm-2'>
                                    <h6>Valor</h6>
                                    <InputCustom
                                        value={valor}
                                        setValue={(event) => {
                                            event.preventDefault();
                                            setValor(event.target.value)
                                        }}
                                        type={'text'}
                                        placeHolder={'Valor'}

                                    />
                                </div>
                                <div className='col-sm-2'>
                                    <h6>Limite de carnes</h6>
                                    <FormControl fullWidth>
                                        <TextField
                                            placeholder='Limite de carnes'
                                            onChange={(event) => {
                                                event.preventDefault();
                                                setCarneLimite(event.target.value)
                                            }}
                                            value={carneLimite}
                                            type="number"
                                            InputProps={{
                                                width: '100%',
                                                sx: {
                                                    height: "39px",
                                                    borderRadius: '0.5rem',
                                                    width: '100%',
                                                    padding: '10px',
                                                }
                                            }} variant="outlined" />
                                    </FormControl>
                                </div>
                                <div className='col-sm-4'>
                                    <h6>Tipo</h6>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={tipo}
                                        style={{
                                            borderRadius: '0.5rem',
                                            height: "39px",
                                            marginLeft: "3px",
                                            marginRight: "3px",
                                            width: '100%'
                                        }}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    borderRadius: '0.5rem',
                                                },
                                            }
                                        }}
                                        onChange={(event) => {
                                            event.preventDefault();
                                            setTipo(event.target.value);
                                        }}
                                    >
                                        <MenuItem value={1}>Marmita</MenuItem>
                                        <MenuItem value={2}>Adicional</MenuItem>
                                    </Select>
                                </div>
                                <div className='col-sm-3'>
                                    <br />
                                    <h6>Categoria</h6>
                                    <SelectCustom
                                        value={categoria}
                                        setValue={(event) => {
                                            event.preventDefault();
                                            setCategoria(event.target.value);
                                        }}
                                        itens={categorias}
                                        valueItem={"id"}
                                        nameItem={"nome"}
                                    />
                                </div>
                                <div className='col-sm-3'>
                                    <br />
                                    <h6>Tamanho</h6>
                                    <SelectCustom
                                        value={tamanho}
                                        setValue={(event) => {
                                            event.preventDefault();
                                            setTamanho(event.target.value);
                                        }}
                                        itens={tamanhosProdutos}
                                        valueItem={"id"}
                                        nameItem={"nome"}
                                    />
                                </div>
                                <div className='col-sm-3'>
                                    <br />
                                    <h6>Imagem</h6>
                                    <input className="form-control" onChange={altererarArquivo} type="file" />
                                </div>
                            </div>
                        </div>
                        <div className='card-footer'>
                            <div className='row'>
                                <div className='col-sm-3'></div>
                                <div className='col-sm-7'></div>
                                <div className='col-sm-2 text-end'>
                                    <ButtonCustom
                                        onClick={() => {
                                            adicionarInformacoes();
                                        }}
                                        color={"error"}
                                        texto={"Confirmar"}
                                        icon={null}
                                    />
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}
